import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg";
import EditProxySessionApi from "./EditProxySessionApi";
import EditSessionsFormik from "../../ui/Dashboard/Sessions/EditSessionsFormik";
import SelectFilter, {filterList} from "../../ui/SelectFilter";
import GetSessionListData from "../../ui/Tables/GetSessionListData";

export default function GetSessionsApi(props) {
    /** fetch data **/
    const [error, setError]       = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData]         = useState([]);
    /** main data **/
    const listCode = 'sessions';
    const [sessionsBase, setSessionsBase] = useState([]);
    const [sessions, setSessions]         = useState([]);
    const [accList, setAccList]           = useState('');
    const [editAccItem, setEditAccItem]   = useState({});
    const [taskCountActionsLimit, setTaskCountActionsLimit] = useState(0);
    /** sort & filter data **/
    const [sort, setSort]                     = useState('id');
    const [filterGroup, setFilterGroup]       = useState(cfg.DEFAULT_FILTERS_VAL);
    const [filterCategory, setFilterCategory] = useState(cfg.DEFAULT_FILTERS_VAL);
    /** checkbox data **/
    const [isMassChanged, setIsMassChanged]   = useState(false);
    /** Counters **/
    const [allCount, setAllCount]         = useState(0);
    const [successCount, setSuccessCount] = useState(0);
    const [restoreCount, setRestoreCount] = useState(0);
    const [banCount, setBanCount]         = useState(0);
    const [proxyCount, setProxyCount]     = useState(0);

    /** First render **/
    useEffect(() => {
        /** интервал обновления апи get-списка **/
        setInterval(async () => {
            if (cfg.IS_GET_LISTS_BY_INTERVAL) {
                setData({data: []});
                setSessions([]);
                await fetchMySessionsApi().then();
            }
        }, cfg.GET_LISTS_INTERVAL);
    }, []);

    /** Добавляет лимит на использование сессий,
     * Если Админ - лимит берется из списка ВСЕХ сессий
     **/
    useEffect(() => {
        // debugger
        if (props.userRole !== 'admin') props.setTaskCountActionsLimit(taskCountActionsLimit);
    }, [taskCountActionsLimit]);

    /** Вызывается для обновления списка, при добавлении/удалении сессий  **/
    useEffect(() => {
        fetchMySessionsApi().then();
    }, [props.run]);

    // todo попробовать совместить дубли
    async function fetchMySessionsApi() {
        // fetch(cfg.getAllSessionsApiUrl, // для теста можно подставить вывод всех сессий
        fetch(cfg.getMySessionsUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token
                })
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    /** Сетит счетчики кол-ва данных (бан, прокси...) **/
    function setCounters(list) {
        if (list[0]) {
            let successAcc = list.filter( item => item.ban === 0 ).length;

            setAllCount     (list.length);
            setSuccessCount (successAcc);
            setBanCount     (list.filter( item => item.ban === 1 ).length);
            setRestoreCount (list.filter( item => item.ban === 2 ).length);
            setProxyCount   (list.filter( item => item.ban === 3 ).length);
            if (successAcc !== taskCountActionsLimit) {
                setTaskCountActionsLimit(successAcc);
            }
        }
    }

    // todo сделать совместную фильтрацию
    /** Переключатель фильтров **/
    function resetFilters(curFilter) {
        if (curFilter === 'category') {
            setFilterGroup(cfg.DEFAULT_FILTERS_VAL);
        } else {
            setFilterCategory(cfg.DEFAULT_FILTERS_VAL);
        }
    }

    // todo убрать дубль кода
    /** Сортиврока **/
    function sortSessions(sort, sessions) {
        let newList = [];
        let sortBy  = 'DESC';
        let sortAccList = document.getElementById('sortAccList');
        if (sortAccList) sortBy = sortAccList.getAttribute('sort-by');

        if (sort === 'id' || sort === 'ban') {
            newList = [...sessions].sort((a,b) => {
                if (sortBy === 'ASC') return a[sort] - b[sort];
                return b[sort] - a[sort];
            });
        } else {
            newList = [...sessions].sort((a,b) => a[sort].localeCompare(b[sort]));
            if (sortBy === 'DESC') newList = newList.reverse();
        }

        setSessions(newList);
        setAccList(getAccList(newList));
    }

    // todo убрать дубль кода
    /** Итоговый рендер (есть html дубли) **/
    function getAccList(newData) {
        return (
            <>
                <div style={{top: '-40px'}} className="toast fade position-absolute bg-white text-bg-light hide"
                     id="MA-mySession"
                     role="alert"
                     aria-live="assertive"
                     aria-atomic="true"
                >
                    <div className="toast-header">
                        <svg className="bd-placeholder-img rounded me-2" width="20" height="20"
                             xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                             preserveAspectRatio="xMidYMid slice" focusable="false">
                            <rect width="100%" height="100%" fill="#007aff"/>
                        </svg>
                        <strong className="me-auto">Mass Actions: </strong>
                        <small className="text-body-secondary">
                            selected: <span id={`${listCode}CheckedCount`}>0</span> of {data.sessions.length}
                        </small>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"/>
                    </div>
                    <div className="toast-body">
                        <div className="cursor-pointer ms-2 me-4">
                            <button disabled=""
                                    className="btn btn-link text-black"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editMySessions"
                                    onClick={e => setIsMassChanged(true)}
                                    onMouseOver={(e) => {
                                      let htmlDataArr = document.getElementById(`${listCode}Array`);
                                      //htmlDataArr = JSON.parse(htmlDataArr.value);
                                      // if (htmlDataArr.length > cfg.PROXY_LIMIT) {
                                      //     props.setMsg(`1 прокси можно добавить только к ${cfg.PROXY_LIMIT} записям`);
                                      //     e.target.disabled = true;
                                      // } else {
                                      //     props.setMsg(`Разрешено редактирование прокси.`);
                                      //     e.target.disabled = false;
                                      // }
                                    }}
                                    onMouseOut={e => e.target.style.pointerEvents = 'all'}
                            >
                                <svg className="bi bi-pencil-square me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>
                                Edit my proxy
                            </button>
                        </div>
                    </div>
                </div>

                <div className="overflow-auto" style={{maxHeight: '250px'}}>
                    <table table-by="selectMySession" className="table">
                        <thead>
                        <tr>
                            <th className="pe-1">
                                <input className="cursor-pointer form-check-input me-3"
                                       type="checkbox"
                                       id="selectMySession"
                                       onChange={(e) => {
                                           let checkboxlist = document.querySelectorAll('[table-by="selectMySession"] [table-item="checkboxMysession"]');
                                           let MA_session = document.getElementById('MA-mySession');
                                           let htmlDataArr = document.getElementById(`${listCode}Array`);

                                           if (e.target.checked) {
                                               MA_session.classList.add('show');
                                               MA_session.classList.remove('hide');
                                               let newArr = [];
                                               Object.keys(checkboxlist).forEach((item, i) => {
                                                   checkboxlist[i].checked = true;
                                                   newArr.push(data.sessions[i].id);
                                               });

                                               htmlDataArr.value = JSON.stringify(newArr);
                                               document.getElementById(`${listCode}CheckedCount`).textContent = newArr.length;
                                           } else {
                                               htmlDataArr.value = '[]';
                                               MA_session.classList.add('hide');
                                               MA_session.classList.remove('show');
                                               Object.keys(checkboxlist).forEach((item, i) => {
                                                    checkboxlist[i].checked = false;
                                               });
                                               document.getElementById(`${listCode}CheckedCount`).textContent = 0;
                                           }
                                       }}
                                />
                            </th>
                            <th>
                                <label className="cursor-pointer" htmlFor="selectMySession">All</label>
                            </th>
                            <th><span className="ms-2">#ID</span></th>
                            <th>BAN status</th>
                            <th>Filename</th>
                            <th>Group</th>
                            <th>Category</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <GetSessionListData
                            listCode={listCode} // unic list code
                            tableBy="selectMySession" // main "select all"
                            tableItem="checkboxMysession" // items with checkbox
                            toastListMA="MA-mySession" // popup mass action
                            modalIdForEditItems="editMySessions" // modal
                            data={data} //fetch data
                            list={newData} // main arr list
                            setEditItem={setEditAccItem} // if edit one item, set obj
                        />
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    /** Изменение прокси сессии и массовое изменение прокси **/
    async function editSession(session) {
        let htmlDataArr = document.getElementById(`${listCode}Array`);
        htmlDataArr = JSON.parse(htmlDataArr.value);
        let dataLength = htmlDataArr.length ?? 1;
        setSessions([]);
        setData({data: []});

        // await props.setLogs([]);
        props.setMsg('Загрузка... смотрите логи.');
        if (data[0]) setData([]);
        if (sessions[0]) setSessions([]);
        let errEdit = [];

        if (isMassChanged && htmlDataArr[0] && session.session_id === null) {
            let curProxy = 0;
            await htmlDataArr.forEach((sessionId, i) => {
                if (i % cfg.PROXY_LIMIT === 0 && i !== 0) {
                    ++curProxy;
                }

                // console.log(`curProxy = ${curProxy}`);
                if (session.new_proxy[curProxy]) {
                    props.logs.push(<EditProxySessionApi
                        logs={props.logs}
                        token={props.token}
                        session={{
                            token: session.token,
                            new_proxy: session.new_proxy[curProxy],
                            session_id: sessionId * 1
                        }}
                        run={Math.random()}
                    />);
                } else {
                    errEdit.push(sessionId);
                }
            })
        } else if (session.session_id !== null) {
            props.logs.push(<EditProxySessionApi
                logs={props.logs}
                token={props.token}
                session={session}
                run={Math.random()}
            />);
        }

        if (errEdit[0]) {
            props.logs.push(`Закончились прокси #ID ${errEdit[0]}-${errEdit[errEdit.length-1]} не изменены`);
        }

        setTimeout(() => {
            setIsMassChanged(false);
            props.setMsg('Готово, смотрите логи');
            fetchMySessionsApi().then();
        }, dataLength * 500);
    }

    /** Уникальный вывод селектов **/
    function getListHeader(list) {
        return (
            <div className="d-flex justify-content-between mb-3 flex-wrap flex-lg-nowrap">
                <div className="d-flex flex-wrap flex-lg-nowrap">
                    <div className="d-flex align-items-center badge bg-primary me-2 cursor-default">
                        <span className="h6 m-0">Всего: {allCount}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">Работают: {successCount}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-info me-2 cursor-default">
                        <span className="h6 m-0">Восстановлено: {restoreCount}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-danger me-2 cursor-default">
                        <span className="h6 m-0">Забанено: {banCount}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-purple me-2 cursor-default">
                        <span className="h6 m-0">Прокси: {proxyCount}</span>
                    </div>
                </div>

                <div className="d-flex flex-wrap flex-lg-nowrap w-100 mt-2 mt-md-0">

                    <button name="updateList" className="btn btn-primary py-2 me-2"
                            style={{width: '40px'}}
                            onClick={(e) => {
                                if (!sessions[0]) return;
                                setData([]);
                                setSessions([]);
                                setAccList(<h6 className="text-center">Загрузка...</h6>);

                                e.target.disabled = true;
                                setTimeout(() => {
                                    fetchMySessionsApi().then(() => {
                                        setAccList(getAccList(sessions));
                                        e.target.disabled = false;
                                    });
                                }, 100);
                            }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </button>

                    <button name="toggleList" sort-by="DESC" id="sortAccList" className="btn btn-primary py-2 me-2"
                         style={{width: '40px'}}
                            onClick={(e) => {
                             if (!sessions[0]) return;
                             if (e.target.getAttribute('sort-by') === 'ASC') {
                                 e.target.setAttribute('sort-by', 'DESC');
                                 e.target.style.transform = 'rotate(180deg)';
                             } else {
                                 e.target.setAttribute('sort-by', 'ASC');
                                 e.target.style.transform = 'rotate(0deg)';
                             }

                             sortSessions(sort, sessions);
                         }}
                    >
                        <span style={{pointerEvents: 'none'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sort-down-alt" viewBox="0 0 16 16">
                              <path d="M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z"/>
                            </svg>
                        </span>
                    </button>

                </div>

                <div className="d-flex flex-wrap flex-lg-nowrap w-100 mt-2 mt-md-0">

                    <SelectFilter
                        optType="group"
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={sessions}
                        filter={filterGroup}
                        setFilter={setFilterGroup}
                        setFilterList={setSessions}
                        setCurList={setAccList}
                        getCurList={getAccList}
                        baseList={sessionsBase}
                        resetFilters={resetFilters}
                        sort={sortSessions}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <SelectFilter
                        optType="category"
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={sessions}
                        filter={filterCategory}
                        setFilter={setFilterCategory}
                        setFilterList={setSessions}
                        setCurList={setAccList}
                        getCurList={getAccList}
                        baseList={sessionsBase}
                        resetFilters={resetFilters}
                        sort={sortSessions}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <select className="p-2" name="session-sort" id="session-sort"
                        value={sort}
                        onChange={e => {
                            setSort(e.target.value);
                            sortSessions(e.target.value, sessions);
                        }}
                    >
                        <option disabled value="">Sort by:</option>
                        <option value="id">id</option>
                        <option value="ban">ban</option>
                        <option value="group">group</option>
                        <option value="category">category</option>
                    </select>


                </div>


            </div>
        )
    }

    if (error) {
        props.logs.push(JSON.stringify(error.message));
        return (
            <div className="d-flex flex-column">
                <span>Что-то не так, обратитесь в тех. поддержку</span>
                <span>Код ошибки: {JSON.stringify(error.message)}</span>
            </div>
        );
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        if (['ok', 'succsess'].includes(data.status)) {
            if (!sessions[0] && data.sessions[0]) {
                data.sessions.reverse();
                setSessionsBase(data.sessions);

                /** Фильтрация если выбраны условия **/
                let filterData = {};
                if (filterGroup    !== cfg.DEFAULT_FILTERS_VAL) filterData = {type: 'group',    filter: filterGroup};
                if (filterCategory !== cfg.DEFAULT_FILTERS_VAL) filterData = {type: 'category', filter: filterCategory};

                if (filterData.type && filterData.filter) {
                    data.sessions = filterList({
                        type: filterData.type,
                        filter: filterData.filter,
                        baseList: sessionsBase ?? data.sessions,
                    });
                }

                /** Получаем уникальные группы, для их выбора в задачах **/
                let unicGroups = [];
                data.sessions.filter((value, index, array) => {
                    if(unicGroups.indexOf(value['group']) === -1) {
                        unicGroups.push(value['group']);
                    }
                });

                /** setTimeout нужен для правильного рендера, без промисов **/
                if (props.setSessionsGroup) {
                    setTimeout(() => {
                    props.setSessionsGroup(unicGroups);
                    })
                }

                // setSessions(data.sessions);            // возможно понадобится
                // setAccList(getAccList(data.sessions)); // возможно понадобится
                /** Сортировка, сетит setSessions и setAccList **/
                sortSessions(sort, data.sessions);

                /** Счётчики кол-ва данных списка **/
                setCounters(data.sessions);
            }
        } else if (sessionsBase[0]) {
            data.sessions = sessionsBase;
        } else {
            return (
                <>
                    <div className="position-relative">
                        {getListHeader(data.sessions)}
                    </div>
                    <h6 className="text-center">Загрузка...</h6>
                </>
            );
        }

        return (
            <div className="position-relative">
                {getListHeader(data.sessions)}

                {data.sessions.length === 0 &&
                    <span className="text-center d-block">Нет записей.</span>
                }

                {data.sessions.length > 0 &&
                <>
                    {accList}

                    {/* modal - edit task */}
                    <div className="modal fade" id="editMySessions" tabIndex="-1" aria-labelledby="editMySessions" style={{display: 'none'}} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">

                                <EditSessionsFormik
                                    editAccItem={editAccItem}
                                    token={props.token}
                                    editSession={editSession}
                                    setLogs={props.setLogs}
                                />

                            </div>
                        </div>
                    </div>
                </>}
            </div>
        );
    }
}
