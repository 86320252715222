import React, {useState, useEffect} from 'react';
import {cfg} from "../../../cfg";

export default function DeleteSessionApi(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    // Примечание: пустой массив зависимостей [] означает, что
    // этот useEffect будет запущен один раз, аналогично componentDidMount()
    useEffect(() => {
        if (props.token && props.account_id) {
            fetch(cfg.delMySessionUrl,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token: props.token,
                        account_id: props.account_id
                    })
                }
            )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
        }
    }, [props.run])

    // debugger
    if (error) {
        return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Загрузка...</div>;
    } else {
        if (data.status) return <div>{data.status}: {data.message}</div>;

        return <div>{data.detail[0].type ? data.detail[0].type + ' : ' : ''} {data.detail[0].msg} {data ? '' : 'см. логи' }</div>;
    }
}
