import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";

const EditAllSessionsFormik = (props) => {
    const [accId, setAccId] = useState(null);

    useEffect(() => {
        if (props.editAccItem.id) setAccId(props.editAccItem.id);
    }, [props.editAccItem]);

    return (
        <>
            <Formik
                initialValues={{ new_proxy: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.new_proxy) errors.new_proxy = 'Обязательное поле.';
                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {
                    const proxy   = document.getElementById("editAllAccountProxy").files[0];
                    const reader  = new FileReader();
                    reader.onload = (eproxy) => {
                        let arr = reader.result.split('\n');
                        props.editSession({ token : props.token, session_id : accId, new_proxy : arr, });
                    };
                    reader.readAsText(proxy);
                    setSubmitting(false);
                    document.querySelector('#editAllSessions button[class="btn-close"]').click();
                }}
            >
                {({isSubmitting}) => (
                    <Form>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="editAutoTaskTitle">
                                Changing session #{accId}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body" style={{maxHeight: '70vh'}}>

                            <label className="mb-2 mt-3" htmlFor="new_proxy">Один файл с proxy ( *.txt )</label>
                            <ErrorMessage name="new_proxy" component="div"/>
                            <Field className="form-control" accept=".txt" type="file" name="new_proxy" id="editAllAccountProxy" />

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>

                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                Save changes
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditAllSessionsFormik;