/**
 * Global Configurations.
 *
 * @type {{getAutoTasksApi: string, sendMessageTelegramUserUrl: string, getMySessionsUrl: string, getAllSessionsApiUrl: string, getTitle: (function(): JSX.Element), authWithTokenUrl: string, delMySessionUrl: string, addTaskApiUrl: string, getTasksApi: string, registrationUrl: string, addAccountApiUrl: string, switchAutoApiUrl: string, editAutoTaskUrl: string, getAuthApi: string}}
 *
 * Docs
 * Production: IP: http://147.45.111.226:8000/docs
 * Stage: IP: http://82.97.246.119:8000/docs
 */

const PROD   = 'http://147.45.111.226:8000';
const STAGE  = 'http://82.97.246.119:8000';
const CUR_IP =  PROD;

export const cfg = {
    // Variables
    PROXY_LIMIT: 5, // todo use this val.
    IS_GET_LISTS_BY_INTERVAL: true, // состояние меняется от активности юзера в App.jsx
    GET_LISTS_INTERVAL: 3000, // milliseconds
    DEFAULT_FILTERS_VAL: 'all_listing',
    // Base
    getAuthApi:                 CUR_IP + "/api/auth",
    authWithTokenUrl:           CUR_IP + "/api/authWithToken",
    registrationUrl:            CUR_IP + "/api/registration",
    sendMessageTelegramUserUrl: CUR_IP + "/api/sendMessageTelegramUser", // testing
    // Sessions
    addAccountApiUrl:           CUR_IP + "/api/uploadSession",
    getAllSessionsApiUrl:       CUR_IP + "/api/getAllSessions",
    getMySessionsUrl:           CUR_IP + "/api/getMySessions",
    delMySessionUrl:            CUR_IP + "/api/delMySession",
    editProxyAccountUrl:        CUR_IP + "/api/editProxyAccount",
    // Tasks
    addTaskApiUrl:              CUR_IP + "/api/addTask",
    getTasksApi:                CUR_IP + "/api/getTasks",
    getAutoTasksApi:            CUR_IP + "/api/getAutoTasks",
    switchAutoApiUrl:           CUR_IP + "/api/switchAuto",
    editAutoTaskUrl:            CUR_IP + "/api/editAutoTask",
    // Methods
    getTitle: function() {
        return (CUR_IP === PROD)
            ? <span className="badge bg-danger cursor-pointer">Production!</span>
            : <span className="badge text-bg-info cursor-pointer">Stage 🧩</span>
    },
}
