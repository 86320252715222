import React, {useEffect, useState} from 'react';
import {Outlet, Link} from "react-router-dom";
import {CookiesProvider, useCookies} from "react-cookie";
import {cfg} from '../cfg'

export default function Header(props) {
    function logOut() {
        props.onLogOut({
            token: null,
            isLogged: false
        });
    }

    return (
        <>
            <header className="w-100 mb-4">
                <nav className="py-2 bg-body-tertiary border-bottom">
                    <div className="container d-flex flex-wrap">
                        <ul className="nav me-auto">
                            <li className="nav-item d-flex align-items-center">{cfg.getTitle()}</li>
                            <li className="nav-item"><Link to="/" className="nav-link link-body-emphasis px-2 active" aria-current="page">Home</Link></li>
                            <li className="nav-item"><Link to="/faq" className="nav-link link-body-emphasis px-2">FAQs</Link></li>
                            <li className="nav-item"><Link to="/about" className="nav-link link-body-emphasis px-2">About</Link></li>

                            {props.isLogged &&
                            <>
                                <li className="nav-item"><span className="nav-link link-body-emphasis px-2">|</span></li>
                                <li className="nav-item"><Link to="/dashboard" className="nav-link link-body-emphasis px-2">Dashboard</Link></li>
                            </>
                            }

                        </ul>

                        <CookiesProvider>
                            <div>
                                {props.isLogged ? (
                                    <ul className="nav">
                                        <li className="nav-item p-2"><span>Hello {props.role}!</span></li>
                                        <li className="nav-item">
                                            <Link onClick={logOut} to="/login" className="nav-link link-body-emphasis px-2">logout</Link>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="nav">
                                        <li className="nav-item"><Link to="/login"   className="nav-link link-body-emphasis px-2">Login</Link></li>
                                        <li className="nav-item"><Link to="/sign-up" className="nav-link link-body-emphasis px-2">Sign up</Link></li>
                                    </ul>
                                )}
                            </div>
                        </CookiesProvider>

                    </div>
                </nav>
            </header>

            <div className="container" style={{minHeight: '73vh'}}>
                <div style={{position: 'sticky', top: '20px', zIndex: 9}}
                     className="alert alert-info alert-dismissible fade show"
                     role="alert">
                    {props.msg}
                </div>
                <Outlet />
            </div>
        </>
    );
};
