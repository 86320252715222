import React from 'react';
import Auth from "../components/Auth";
import {Navigate} from "react-router-dom";

export default function Login({ isLogged, onLogin, setMsg }) {
    if (isLogged) return <Navigate to="/dashboard" />;

    return (
        <div className="d-flex align-items-center">
            <Auth onLogin={onLogin} setMsg={setMsg}/>
        </div>
    );
};
