import {banStatus, taskTypes} from "../../staticData/UI_Data";
import React from "react";
import SwitchAuto from "../../api/Tasks/SwitchAuto";

const GetTaskListData = (props) => {
    const isActive = {0 : true, 1 : false}
    let inputCheckedVal = props.listCode + 'Array';
    let renderList = props.list.map((item) => {
        return (
            <tr key={item.task_id}>
                <td className="pe-1">
                    <input className="cursor-pointer form-check-input"
                       type="checkbox"
                       table-item={props.tableItem}
                       value={item.task_id}
                       id={`${props.listCode}-${item.task_id}`}
                       onChange={(item) => {
                           let htmlDataArr = document.getElementById(inputCheckedVal);
                           let newArr = htmlDataArr.value !== '' ? JSON.parse(htmlDataArr.value) : '';
                           if (item.target.checked) {
                               newArr.push(item.target.value * 1);
                           } else {
                               newArr.splice(newArr.indexOf(item.target.value * 1), 1);
                           }

                            htmlDataArr.value = JSON.stringify(newArr);
                            document.getElementById(`${props.listCode}CheckedCount`).textContent = newArr.length;

                           let selectTask = document.getElementById(props.tableBy);
                           let toastListMA = document.getElementById(props.toastListMA);
                           if (newArr.length === 0 ) {
                               selectTask.checked = false;
                               selectTask.indeterminate = false;
                               toastListMA.classList.add('hide');
                               toastListMA.classList.remove('show');
                           } else {
                               toastListMA.classList.add('show');
                               toastListMA.classList.remove('hide');
                               if (newArr.length === props.data.data.length) {
                                   selectTask.checked = true;
                               } else {
                                   selectTask.indeterminate = true;
                               }
                            }
                        }}
                    />
                </td>
                <td>
                    <span className="cursor-pointer me-3"
                          data-bs-toggle="modal"
                          data-bs-target={`#${props.modalIdForEditItems}`}
                          onClick={() => {
                              props.setEditItem(item);
                          }}
                    >
                        <svg className="bi bi-pencil-square" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                    </span>
                </td>
                <th scope="row">#{item.task_id}</th>
                <td>{item.task_id_channel}</td>
                <td style={{minWidth: '100px'}}>{item.task_count_actions_compite} of {item.task_count_actions}</td>
                <td>{item.task_time_out}</td>
                <td>{item.count_action_per_timeout}</td>
                <td>{item.percetn_wave}</td>
                <td>{item.percent_markup_spread}</td>
                <td style={{minWidth: '120px'}}>{JSON.parse(item.task_obj)}</td>
                <td>{item.task_target.replace('https://', '')}</td>
                <td >{item.task_group ?? 'empty'}</td>
                <td style={{minWidth: '90px'}}>{taskTypes[item.task_type]} {item.task_type}</td>
                <td style={{minWidth: '170px'}}>{item.dateAdd ?? 'empty'}</td>

                {props.isAuto &&
                    <td className="col form-switch px-2 m-0" >
                        <input auto-task-id={item.task_id} name="task_auto" id="" type="checkbox" className="radio-checkbox-list form-check-input cursor-pointer m-auto"
                               value={isActive[item.active]}
                               defaultChecked={isActive[item.active]}
                               onClick={(e) => {
                                   let autoTaskId = e.target.getAttribute('auto-task-id');
                                   props.setTasksAuto([]);
                                   props.setData({data: [], status: null});
                                   props.setMsg(<SwitchAuto token={props.token} autoTaskId={autoTaskId} run={Math.random()} />)

                                   setTimeout(() => {
                                       props.fetchGetTasksAutoApi().then();
                                   }, 200)
                               }}
                        />
                    </td>
                }
            </tr>
        );
    });

    return (
        <>
            <tr className="d-none">
                <td>
                    <input id={inputCheckedVal} type="text" defaultValue="[]"/>
                </td>
            </tr>
            {renderList}
        </>
    )
};

export default GetTaskListData;