import React, {useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import {cfg} from '../../cfg'

export default function AuthApi(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(cfg.getAuthApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: props.email,
                password: props.password
            })
        })
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setData(result);
            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [])

    async function onLogin(data) {
        await new Promise(() => {
            setTimeout(() => {
                props.onLogin({
                    token: data.token,
                    role: data.role,
                    isLogged: true
                });
            }, 10);
        });
    }

    if (error) {
        return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        return ('Загрузка...');
    } else {
        if (data.status === 'success' || data.status === 'ok') {
            onLogin(data).then(() => {
                return <Navigate to="/dashboard" />
            });
        }

        return (<>{data.status}: {data.message}</>);
    }
}
