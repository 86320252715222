import React, {useState, useEffect} from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// define the OptionType for your app
type MyOption = {
    label: string;
    value: any;
};

//define the group option type
type GroupedOption = {
    label: string; // group label
    options: MyOption[];
};

// component props
type Props = {
    name: string;
    value: any;
} & Omit<
    StateManagerProps<MyOption, false | true, GroupedOption>,
    "value" | "onChange"
    >;

const FormikReactSelect = (props: Props) => {
    const { name, ...restProps } = props;
    const [field] = useField(name);
    const { setFieldValue } = useFormikContext();
    const [curValue, setCurValue] = useState([]);

    //flatten the options so that it will be easier to find the value
    const flattenedOptions = props.options?.flatMap((o) => {
        const isNotGrouped = "value" in o;
        if (isNotGrouped) {
            return o;
        } else {
            return o.options;
        }
    });

    //get the value using flattenedOptions and field.value
    let value = flattenedOptions?.filter((o) => {
        const isArrayValue = Array.isArray(field.value);

        if (isArrayValue) {
            const values = field.value as Array<any>;
            return values.includes(o.value);
        } else {
            return field.value === o.value;
        }
    });

    // useEffect(() => {
    //     if (typeof value === 'object' && typeof props.value === 'object') {
    //         value = [...value, ...props.value];
    //     }
    // }, []);

    // if (typeof value === 'object' && typeof props.value === 'object') {
    //     value = [...value, ...props.value];
    // }

    return (
        <Select
            {...restProps}
            value={value}
            // onChange implementation
            onChange={(val) => {
                //here I used explicit typing but there maybe a better way to type the value.
                const _val = val as MyOption[] | MyOption;
                const isArray = Array.isArray(_val);
                if (isArray) {
                    const values = _val.map((o) => o.value);
                    setFieldValue(name, values);
                } else {
                    setFieldValue(name, _val.value);
                }
            }}
        />
    );
};

export default FormikReactSelect;