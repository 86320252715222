import React, {useEffect, useState} from 'react';

export async function Fetching(props) {
    let data = {};
    await fetch(props.request,
        {
            method: props.method,
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(props.values) ?? null
        }
    )
        .then(res => res.json())
        .then(
            (res) => {
                data = res;
            }
        );

    if (props.debug) debugger;
    return data;
}

async function FetchData(props) {
    /** fetch data **/
    const [error, setError]       = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData]         = useState({data: []});

    if (props.debug) debugger;

    useEffect(() => {
        fetching().then();
    }, [props.run]);

    async function fetching() {
        if (props.debug) debugger;
        await fetch(props.request,
            {
                method: props.method,
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(props.values) ?? null
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    if (error) {
        // props.logs.push(JSON.stringify(error));
        return (
            <div className="d-flex flex-column">
                <span>Что-то не так, обратитесь в тех. поддержку</span>
                <span>Код ошибки: {JSON.stringify(error.message)}</span>
            </div>
        );
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        return data;
    }
};

export default FetchData;