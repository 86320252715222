import React from 'react';
import SignUpForm from "../components/SignUp";
import {Navigate} from "react-router-dom";

export default function SignUp({isLogged, onSignUp, setMsg}) {
    if (isLogged) return <Navigate to="/dashboard" />;

    return (
        <div className="d-flex align-items-center">
            <SignUpForm onSignUp={onSignUp} setMsg={setMsg} />
        </div>
    );
};
