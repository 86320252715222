import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg.js";
import {taskTypes} from "../../staticData/UI_Data";
import SwitchAuto from "./SwitchAuto";
import EditAutoTaskFormik from "../../ui/Dashboard/Tasks/EditAutoTaskFormik";
import EditAutoTaskApi from "./EditAutoTaskApi";
import SelectFilter, {filterList} from "../../ui/SelectFilter";
import GetTaskListData from "../../ui/Tables/GetTaskListData";

const GetTasksAutoApi = (props) => {
    /** fetch data **/
    const isActive = {0 : true, 1 : false}
    const [error, setError]       = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData]         = useState({data: []});
    /** main data **/
    const listCode = 'taskAuto';
    const [tasksBase, setTasksBase]       = useState([]);
    const [tasksAuto, setTasksAuto]       = useState([]);
    const [autoTaskList, setAutoTaskList] = useState('');
    const [editTaskItem, setEditTaskItem] = useState({});
    /** sort & filter data **/
    const [sort, setSort]     = useState('task_id');
    const [filterGroup, setFilterGroup]   = useState(cfg.DEFAULT_FILTERS_VAL);
    const [filterType, setFilterType]     = useState(cfg.DEFAULT_FILTERS_VAL);
    const [filterTarget, setFilterTarget] = useState(cfg.DEFAULT_FILTERS_VAL);
    /** checkbox data **/
    const [isMassChanged, setIsMassChanged]   = useState(false);
    /** Counters **/
    const [allCount, setAllCount] = useState(0);
    const [subs, setSubs]         = useState(0);
    const [view, setView]         = useState(0);
    const [react, setReact]       = useState(0);
    const [active, setActive]     = useState(0);

    /** First render **/
    useEffect(() => {
        /** интервал обновления апи get-списка **/
        setInterval(async () => {
            if (cfg.IS_GET_LISTS_BY_INTERVAL) {
                setData({data: []});
                setTasksAuto([]);
                await fetchGetTasksAutoApi().then();
            }
        }, cfg.GET_LISTS_INTERVAL);
    }, []);

    /** Вызывается для обновления списка **/
    useEffect(() => {
        fetchGetTasksAutoApi().then();
    }, [props.run]);

    // todo попробовать совместить дубли
    async function fetchGetTasksAutoApi() {
        // todo убрать лишнее
        // setTimeout(() => {
            setData([]);
            setTasksAuto([]);
        // })
         await fetch(cfg.getAutoTasksApi,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token
                })
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                    // todo убрать лишнее
                    setCounters(result.data);
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    /** Сетит счетчики кол-ва данных **/
    function setCounters(list) {
        if (list[0]) {
            setAllCount (list.length);
            setSubs     (list.filter(item => item.task_type === 'subs').length);
            setView     (list.filter(item => item.task_type === 'view').length);
            setReact    (list.filter(item => item.task_type === 'react').length);
            setActive   (list.filter(item => item.active    === 0).length);
        }
    }

    // todo сделать совместную фильтрацию
    /** Переключатель фильтров **/
    function resetFilters(curFilter) {
        if (curFilter === 'type') {
            setFilterGroup  (cfg.DEFAULT_FILTERS_VAL);
            setFilterTarget (cfg.DEFAULT_FILTERS_VAL);
        } else if (curFilter === 'group') {
            setFilterType   (cfg.DEFAULT_FILTERS_VAL);
            setFilterTarget (cfg.DEFAULT_FILTERS_VAL);
        } else if (curFilter === 'target') {
            setFilterType  (cfg.DEFAULT_FILTERS_VAL);
            setFilterGroup (cfg.DEFAULT_FILTERS_VAL);
        }
    }

    // todo убрать дубль кода
    /** Сортиврока **/
    function sortTasksAuto(sort, tasksAuto) {
        let newList = [];
        let sortBy  = 'DESC';
        let sortTaskAutoList = document.getElementById('sortTaskAutoList');
        if (sortTaskAutoList) sortBy = sortTaskAutoList.getAttribute('sort-by');
        if (['task_id','active','task_count_actions'].includes(sort)) {
            newList = [...tasksAuto].sort((a,b) => {
                if (sortBy === 'ASC') return a[sort] - b[sort];
                return b[sort] - a[sort];
            });
        } else {
            newList = [...tasksAuto].sort((a,b) => a[sort].localeCompare(b[sort]));
            if (sortBy === 'DESC') newList = newList.reverse();
        }

        setTasksAuto(newList);
        setAutoTaskList(getAutoTasksList(newList));
    }

    // todo убрать дубль кода
    /** Итоговый рендер (есть html дубли) **/
    function getAutoTasksList(newData) {
        return (
            <>
                <div style={{top: '-40px'}} className="toast fade position-absolute bg-white text-bg-light hide"
                     id="MA-autoTask"
                     role="alert"
                     aria-live="assertive"
                     aria-atomic="true"
                >
                    <div className="toast-header">
                        <svg className="bd-placeholder-img rounded me-2" width="20" height="20"
                             xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                             preserveAspectRatio="xMidYMid slice" focusable="false">
                            <rect width="100%" height="100%" fill="#007aff"/>
                        </svg>
                        <strong className="me-auto">Mass Actions: </strong>
                        <small className="text-body-secondary">
                            selected: <span id={`${listCode}CheckedCount`}>0</span> of {data.data.length}
                        </small>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"/>
                    </div>
                    <div className="toast-body">
                        <button disabled=""
                              className="btn btn-link text-black"
                              data-bs-toggle="modal"
                              data-bs-target="#editAutoTask"
                              onClick={e => setIsMassChanged(true)}
                        >
                            <svg className="bi bi-pencil-square me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>
                            Edit tasks auto
                        </button>
                    </div>
                </div>

                <table table-by="selectAutoTask" className="table table-striped d-block overflow-auto"
                       style={{maxHeight: '250px'}}>
                    <thead>
                    <tr>
                        <th className="pe-1">
                            <input className="cursor-pointer form-check-input"
                                   type="checkbox"
                                   id="selectAutoTask"
                                   onChange={(e) => {
                                       let checkboxlist = document.querySelectorAll('[table-by="selectAutoTask"] [table-item="checkboxAutoTask"]');
                                       let MA_task = document.getElementById('MA-autoTask');
                                       let htmlDataArr = document.getElementById(`${listCode}Array`);

                                       if (e.target.checked) {
                                           MA_task.classList.add('show');
                                           MA_task.classList.remove('hide');
                                           let newArr = [];
                                           Object.keys(checkboxlist).forEach((item, i) => {
                                               checkboxlist[i].checked = true;
                                               newArr.push(data.data[i].task_id);
                                           })
                                           document.getElementById(`${listCode}CheckedCount`).textContent = newArr.length;
                                       } else {
                                           htmlDataArr.value = '[]';
                                           MA_task.classList.add('hide');
                                           MA_task.classList.remove('show');
                                           Object.keys(checkboxlist).forEach((item, i) => {
                                               checkboxlist[i].checked = false;
                                           })
                                           document.getElementById(`${listCode}CheckedCount`).textContent = 0;
                                       }
                                   }}
                            />
                        </th>
                        <th>
                            <label className="cursor-pointer" htmlFor="selectAutoTask">All</label>
                        </th>
                        <th>#ID</th>
                        <th>Id chan.</th>
                        <th>Actions</th>
                        <th style={{minWidth: '100px'}}>Time out</th>
                        <th style={{minWidth: '110px'}}>Per timeout</th>
                        <th>Wave</th>
                        <th style={{minWidth: '100px'}}>Markup %</th>
                        <th className="col-2">Reactions</th>
                        <th>Target</th>
                        <th>Group</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th style={{minWidth: '100px'}}>
                            <div className="d-flex flex-column">
                                <span>Status</span>
                                <span>off / on</span>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <GetTaskListData
                            listCode={listCode} // unic list code
                            tableBy="selectAutoTask" // main "select all"
                            tableItem="checkboxAutoTask" // items with checkbox
                            toastListMA="MA-autoTask" // popup mass action
                            modalIdForEditItems="editAutoTask" // modal
                            data={data} //fetch data
                            setData={setData} //fetch data
                            list={newData} // main arr list
                            setEditItem={setEditTaskItem} // if edit one item, set obj
                            isAuto={true}
                            setTasksAuto={setTasksAuto}
                            setMsg={props.setMsg}
                            token={props.token}
                            fetchGetTasksAutoApi={fetchGetTasksAutoApi}
                        />
                    </tbody>
                </table>
            </>
        );
    }

    async function editTask(task) {
        let htmlDataArr = document.getElementById(`${listCode}Array`);
        htmlDataArr = JSON.parse(htmlDataArr);
        let dataLength = htmlDataArr.length ?? 1;
        setTasksAuto([]);
        setData({data: []});

        props.setMsg('Загрузка...');
        if (data[0])      setData([]);
        if (tasksAuto[0]) setTasksAuto([]);

        if (isMassChanged && htmlDataArr[0] && !task.edit_task_id) {
            await htmlDataArr.forEach((taskId, i) => {
                props.logs.push(<EditAutoTaskApi
                    logs={props.logs}
                    token={props.token}
                    task={{
                        token : props.token,
                        edit_task_id : taskId * 1,
                        edit_task_auto : task.edit_task_auto ?? true,
                        edit_task_channel_id : task.edit_task_channel_id,
                        edit_task_type : task.edit_task_type,
                        edit_task_count_actions : task.edit_task_count_actions,
                        edit_task_obj : task.edit_task_obj,
                        edit_task_time_out : task.edit_task_time_out,
                        edit_count_action_per_timeout : task.edit_count_action_per_timeout,
                        edit_percetn_wave : task.edit_percetn_wave,
                        edit_percent_markup_spread : task.edit_percent_markup_spread,
                        task_group : task.task_group,
                    }}
                    run={Math.random()}
                />);
            })
        } else if (task.edit_task_id) {
            props.logs.push(<EditAutoTaskApi
                logs={props.logs}
                token={props.token}
                task={task}
                run={Math.random()}
            />);
        }

        setTimeout(() => {
            setIsMassChanged(false);
            props.setMsg('Готово, смотрите логи');
            fetchGetTasksAutoApi().then();
        }, dataLength * 500);
    }

    /** Панель управления авто-задачами (статусы/фильтры/сортировка) **/
    function getListHeader(list) {
        return (
            <div className="d-flex justify-content-between mb-3 flex-wrap flex-lg-nowrap">
                <div className="d-flex flex-wrap flex-lg-nowrap">
                    <div className="d-flex align-items-center badge bg-primary me-2 cursor-default">
                        <span className="h6 m-0">Всего: {allCount}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">subs: {subs}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">view: {view}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">react: {react}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-purple me-2 cursor-default">
                        <span className="h6 m-0">
                            Active: {active}
                            <b> of </b> {allCount}
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-wrap flex-lg-nowrap mt-2 mt-lg-0">

                    <button name="updateList" className="btn btn-primary py-2 me-2"
                            style={{maxHeight: '250px'}}
                            onClick={(e) => {
                                if (!tasksAuto[0]) return;
                                setData({data: []});
                                setTasksAuto([]);
                                setAutoTaskList(<h6 className="text-center">Загрузка...</h6>);

                                e.target.disabled = true;
                                setTimeout(() => {
                                    fetchGetTasksAutoApi().then(() => {
                                        setAutoTaskList(getAutoTasksList(tasksAuto));
                                        e.target.disabled = false;
                                    });
                                }, 100);
                            }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </button>

                    <div name="toggleList" sort-by="DESC" id="sortTaskAutoList" className="btn btn-primary py-2 me-2"
                         style={{maxHeight: '250px'}}
                         onClick={(e) => {
                             if (!tasksAuto[0]) return;
                             if (e.target.getAttribute('sort-by') === 'ASC') {
                                 e.target.setAttribute('sort-by', 'DESC');
                                 e.target.style.transform = 'rotate(180deg)';
                             } else {
                                 e.target.setAttribute('sort-by', 'ASC');
                                 e.target.style.transform = 'rotate(0deg)';
                             }

                             sortTasksAuto(sort, tasksAuto);
                         }}
                    >
                        <span style={{pointerEvents: 'none'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sort-down-alt" viewBox="0 0 16 16">
                              <path d="M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z"/>
                            </svg>
                        </span>
                    </div>
                </div>


                <div className="d-flex flex-wrap flex-lg-nowrap mt-2 mt-lg-0">
                    <SelectFilter
                        optType="target"
                        optTypePrefix="task_" // default - empty
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={tasksAuto}
                        filter={filterTarget}
                        setFilter={setFilterTarget}
                        setFilterList={setTasksAuto}
                        setCurList={setAutoTaskList}
                        getCurList={getAutoTasksList}
                        baseList={tasksBase}
                        resetFilters={resetFilters}
                        sort={sortTasksAuto}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <SelectFilter
                        optType="group"
                        optTypePrefix="task_" // default - empty
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={tasksAuto}
                        filter={filterGroup}
                        setFilter={setFilterGroup}
                        setFilterList={setTasksAuto}
                        setCurList={setAutoTaskList}
                        getCurList={getAutoTasksList}
                        baseList={tasksBase}
                        resetFilters={resetFilters}
                        sort={sortTasksAuto}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <SelectFilter
                        optType="type"
                        optTypePrefix="task_" // default - empty
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={tasksAuto}
                        filter={filterType}
                        setFilter={setFilterType}
                        setFilterList={setTasksAuto}
                        setCurList={setAutoTaskList}
                        getCurList={getAutoTasksList}
                        baseList={tasksBase}
                        resetFilters={resetFilters}
                        sort={sortTasksAuto}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <select className="p-2" name="task-sort" id="task-sort"
                            style={{maxWidth: '130px'}}
                            value={sort}
                            onChange={e => {
                                setSort(e.target.value);
                                sortTasksAuto(e.target.value, tasksAuto);
                            }}
                    >
                        <option disabled value="">Сортировать по: </option>
                        <option value="task_id">Id</option>
                        <option value="task_id_channel">Id chanel</option>
                        <option value="task_count_actions">Count actions</option>
                        <option value="task_time_out">Time out</option>
                        <option value="task_obj">Reactions</option>
                        <option value="task_type">Target</option>
                        <option value="task_type">Group</option>
                        <option value="task_type">Type</option>
                        <option value="active">Active off/on</option>
                    </select>
                </div>
                </div>
        )
    }

    if (error) {
        props.logs.push(JSON.stringify(error.message));
        return (
            <div className="d-flex flex-column">
                <span>Что-то не так, обратитесь в тех. поддержку</span>
                <span>Код ошибки: {JSON.stringify(error.message)}</span>
            </div>
        );
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        if (['ok', 'succsess'].includes(data.status)) {
            if (!tasksAuto[0] && data.data[0]) {
                data.data.reverse();
                if (!tasksBase[0]) setTasksBase(data.data);

                /** Фильтрация если выбраны условия **/
                let filterData = {};
                if (filterType   !== 'all_listing') filterData = {type: 'task_type',   filter: filterType};
                if (filterGroup  !== 'all_listing') filterData = {type: 'task_group',  filter: filterGroup};
                if (filterTarget !== 'all_listing') filterData = {type: 'task_target', filter: filterTarget};
                if (filterData.type && filterData.filter) {
                    data.data = filterList({
                        type: filterData.type,
                        filter: filterData.filter,
                        baseList: data.data,
                    });
                }

                setTasksAuto(data.data);
                // setTasks(data.data);               // возможно понадобится
                // setAccList(getAccList(data.data)); // возможно понадобится
                /** Сортировка, сетит setTasks и setTaskList **/
                sortTasksAuto(sort, data.data);
                /** Счётчики кол-ва данных списка **/
                setCounters(data.data);
            }
        } else if (tasksBase[0]) {
            data.data = tasksBase;
        } else {
            return (
                <>
                    <div className="position-relative">
                        {getListHeader(data.data)}
                    </div>
                    <h6 className="text-center">Загрузка...</h6>
                </>
            );
        }

        return (
            <div className="position-relative">
                {getListHeader(data.data)}

                {data.data.length === 0 &&
                    <span className="text-center d-block">Нет записей.</span>
                }

                {data.data.length > 0 &&
                <>
                    {autoTaskList}
                    {/* modal - edit task */}
                    <div className="modal fade" id="editAutoTask" tabIndex="-1" aria-labelledby="editAutoTaskTitle" style={{display: 'none'}} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">

                                <EditAutoTaskFormik
                                    editItem={editTaskItem}
                                    token={props.token}
                                    editTask={editTask}
                                    groupList={props.curUnicGroups}
                                />

                            </div>
                        </div>
                    </div>
                </>}
            </div>
        );
    }
}

export default GetTasksAutoApi;