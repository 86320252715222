/** статусы работоспособности сессий **/
export const banStatus = {
    0: {text: 'Работает', color: '#19b219'},
    1: {text: 'Бан', color: '#ff0000'},
    2: {text: 'Восстановлен', color: '#6edff6'},
    3: {text: 'Прокси', color: '#ab50b8'}
};

/** Визуал для вывода задач **/
export const taskTypes = {
    'view' : '👀',
    'subs' : '🔔',
    'react' : '🙂',
};