import React, {useState} from 'react';
import {cfg} from '../../cfg'

export function filterList(props) {
    let newList = [];
    if (props.filter === cfg.DEFAULT_FILTERS_VAL) {
        newList = props.baseList;
    } else {
        newList = props.baseList.filter( item => item[props.type] === props.filter);
    }

    if (props.setCounters) {
        props.setCounters(newList);
    }

    /** Итог: **/
    if (props.sort && props.sortBy) {
        /** сортировка сама сетит данные **/
        props.sort(props.sortBy, newList);
    } else if (props.setFilterList && props.setCurList && props.getCurList) {
        /** сработает при выборе опции из select списка **/
        props.setFilterList(newList);
        props.setCurList(props.getCurList(newList));
    } else {
        /** Отдает новый, отфильтрованный список,
         *  например при автообновлении списка (по интервалу)
         **/
        return newList;
    }
}

const SelectFilter = (props) => {
    const [options, setOptions] = useState([]);
    let optTypePrefix = props.optTypePrefix ?? '';

    /* UNIC values */
    props.options.filter((value, index, array) => {
        if(options.indexOf(value[optTypePrefix + props.optType]) === -1) {
            options.push(value[optTypePrefix + props.optType]);
        }
    });

    return (
        <select style={{maxWidth: '120px'}} className="p-2 me-2"
            // name="session-sort" id="session-sort"
            value={props.filter}
            onChange={e => {
                if (props.resetFilters) props.resetFilters(props.optType);

                filterList({
                    type: optTypePrefix + props.optType,
                    filter: e.target.value,
                    baseList: props.baseList,
                    setFilterList: props.setFilterList,
                    setCurList: props.setCurList,
                    getCurList: props.getCurList,
                    sort: props.sort,
                    sortBy: props.sortBy,
                    setCounters: props.setCounters ?? false
                }

                );
                props.setFilter(e.target.value);
            }}
        >
            {props.defaultOption}
            <option value={cfg.DEFAULT_FILTERS_VAL}>All {props.optType}</option>
            {options.map((value, i) => {
                return (<option key={i} value={value}>{value}</option>);
            })}
        </select>
    );
}

export default SelectFilter;