import React from 'react';

const Logs = ({logs}) => {
    return (
        <div className="accordion mb-3" id="logs">
            <div className="accordion-item">
                <h4 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseLogs" aria-expanded="false" aria-controls="collapseLogs">
                        Логи
                    </button>
                </h4>
                <div id="collapseLogs" className="accordion-collapse collapse" data-bs-parent="#logs" >
                    <div style={{overflow: 'auto', maxHeight: '300px'}} className="accordion-body">

                        <span>Кол-во данных: {logs.length}</span>
                        <hr/>
                        {logs.map((item, i) => {
                            if (item.id && item.proxy) {
                                return (<>
                                    <div key={item.id} className="d-flex justify-content-between">
                                        <span style={{width: 60}} className="me-2"><b>ID:</b> {item.id ? item.id : 'null'}</span>
                                        <span className="me-3"><b>Group:</b> {item.group ? item.group : 'null'}</span>
                                        <span className="me-3"><b>Category:</b> {item.category ? item.category : 'null'}</span>
                                        <span className="me-3"><b>Data:</b> {item.data ? item.data : 'null'}</span>
                                        <span className="me-3"><b>Error:</b> {item.error ? item.error : 'null'}</span>
                                        <span className=""><b>Proxy:</b> {item.proxy ? item.proxy : 'null'}</span>
                                    </div>
                                    <hr/>
                                </>);
                            } else {
                                return <div key={i}>{item}</div>
                            }
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Logs;