import React, {useState} from 'react';
import FormikReactSelect from "../../FormikReactSelect";
import {taskReactions} from "../../../staticData/TaskReactions";
import {ErrorMessage, Field, Form, Formik} from "formik";

const AddTaskFormik = ({token, addTask, taskCountActionsLimit, groupList}) => {
    const [menuIsOpenTask, setMenuIsOpenTask]  = useState(false);
    const [taskType, setTaskType]              = useState('subs');
    const [group, setGroup]                    = useState('all');
    const [isTaskAuto, setIsTaskAuto]          = useState(false);

    function getGroupList() {
        let options = []
        groupList.map((e) => {
            options.push(<option key={e} value={e}>{e}</option>);
        });

        return options;
    }

    return (
        <Formik
            initialValues={{
                task_auto: false,
                task_channel_id: '',
                task_type: 'subs',
                task_target: '',
                task_count_actions: 1,
                task_obj: [],
                task_time_out: 1,
                count_action_per_timeout: 1,
                percetn_wave: 0,
                percent_markup_spread: 0,
                group: 'all',
            }}
            validate={values => {
                const errors = {};
                let taskType = document.getElementById('task_type').value;
                let group = document.getElementById('addTask_group').value;
                let isTaskAuto = document.getElementById('task_auto').checked;

                values.task_type = taskType;
                values.group     = group;
                values.task_auto = isTaskAuto;

                if (!values.task_type)                           errors.task_type = 'Обязательное поле.';
                if (!values.task_target)                         errors.task_target = 'Обязательное поле.';
                if (!values.group)                               errors.group = 'Обязательное поле.';
                if (!values.task_count_actions)                  errors.task_count_actions = 'Обязательное поле.';
                if (!values.task_time_out)                       errors.task_time_out = 'Обязательное поле.';
                if (!values.count_action_per_timeout)            errors.count_action_per_timeout = 'Обязательное поле.';
                if (values.percetn_wave === '')                  errors.percetn_wave = 'Обязательное поле.';
                if (values.percent_markup_spread === '')         errors.percent_markup_spread = 'Обязательное поле.';
                if (!values.task_obj[0] && taskType === 'react') errors.task_obj = 'Обязательное поле.';
                if (!values.task_channel_id &&
                    (taskType !== 'subs' && isTaskAuto || taskType === 'subs')) {
                    errors.task_channel_id = 'Обязательное поле.';
                }

                return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                    if (!values.task_auto && values.task_type !=='subs') values.task_channel_id = '';
                    if (values.task_type !=='react') values.task_obj = [];

                    addTask(token, values);
                    setSubmitting(false);
                }, 400);
            }}
        >
            {({isSubmitting}) => (
                <Form>
                    <ErrorMessage name="task_type" component="div"/>
                    <div className="form-floating mt-3">
                        <Field as="select" className="form-control" id="task_type" type="text" name="task_type"
                               value={taskType}
                               onChange={(e) => {
                                   let task_auto_box   = document.getElementById('task_auto_box');
                                   let task_auto       = document.getElementById('task_auto');
                                   let task_channel_id = document.getElementById('task_channel_id_box');
                                   setTaskType(e.target.value);
                                   if (e.target.value !== 'subs' ) {
                                       task_auto.checked
                                           ? task_channel_id.style.display = 'block'
                                           : task_channel_id.style.display = 'none';

                                       task_auto_box.style.display = 'block';
                                   } else {
                                       task_channel_id.style.display = 'block';
                                       task_auto_box.style.display = 'none';
                                   }

                                   let task_obj_box = document.getElementById('task_obj_box');
                                   if (e.target.value === 'react' ) {
                                       task_obj_box.style.display = 'block';
                                   } else {
                                       task_obj_box.style.display = 'none';
                                   }
                               }}
                        >
                            <option value="subs">Подписка</option>
                            <option value="view">Просмотры</option>
                            <option value="react">Реакции</option>
                        </Field>
                        <label htmlFor="task_type">Type</label>
                    </div>

                    <div style={{display: 'none'}} id="task_auto_box" className="my-3 form-check form-switch">
                        <label className="cursor-pointer">
                            <Field id="task_auto" className="form-check-input cursor-pointer radio-checkbox-list my-0 me-2" type="checkbox" name="task_auto"
                               checked={isTaskAuto}
                               value={isTaskAuto}
                               onChange={(e) => {
                                   let task_channel_id = document.getElementById('task_channel_id_box');
                                   setIsTaskAuto(!isTaskAuto);
                                   if (e.target.checked) {
                                       task_channel_id.style.display = 'block';
                                       document.getElementById('task_channel_id').focus();
                                   } else {
                                    task_channel_id.style.display = 'none';
                                   }
                               }}
                            />
                            Auto
                        </label>
                    </div>

                    <div id="task_channel_id_box" className="mt-3">
                        <ErrorMessage name="task_channel_id" component="div"/>
                        <div className="form-floating mt-3">
                            <Field id="task_channel_id" className="form-control" type="text" name="task_channel_id"/>
                            <label htmlFor="task_channel_id">Channel ID</label>
                        </div>
                    </div>

                    <ErrorMessage name="task_target" component="div"/>
                    <div className="form-floating mt-3">
                        <Field className="form-control" id="task_target" type="text"
                               name="task_target"/>
                        <label htmlFor="task_target">Target (URL)</label>
                    </div>

                    <ErrorMessage name="group" component="div"/>
                    <div className="form-floating mt-3">
                        <Field as="select" className="form-control" id="addTask_group" type="text" name="group"
                               value={group}
                               onChange={(e) => {
                                   setGroup(e.target.value);
                               }}
                        >
                            <option value="all">All</option>
                            {getGroupList()}
                        </Field>
                        <label htmlFor="group">group</label>
                    </div>

                    <ErrorMessage name="task_count_actions" component="div"/>
                    <div className="form-floating mt-3">
                        <Field min="1"
                               max={taskCountActionsLimit}
                               className="form-control"
                               id="task_count_actions"
                               type="number"
                               name="task_count_actions"/>
                        <label htmlFor="task_count_actions">Actions count ({`from 1 to ${taskCountActionsLimit}`})</label>
                        {/*<label htmlFor="task_count_actions">Actions count</label>*/}
                    </div>

                    <ErrorMessage name="task_obj" component="div"/>
                    <div style={{display: 'none'}} id="task_obj_box" className="form-floating mt-3">

                        <FormikReactSelect
                            className=""
                            id="task_obj"
                            name="task_obj"
                            isMulti={true}
                            options={taskReactions}
                            placeholder="Добавьте реакции.."
                            components={{
                                IndicatorSeparator: () => null // возле стрелки скрываем разделитель "|"
                            }}
                            menuIsOpen={menuIsOpenTask} // состояние открытия select
                            onChange={() => {
                                this.setState({ menuIsOpen: true});
                            }}
                            onFocus={ () => {setMenuIsOpenTask(!menuIsOpenTask)}}
                            onBlur={ () => {setMenuIsOpenTask(!menuIsOpenTask)}}
                            // menuIsOpen={true} // раскрывает список для тестирования меню.
                        />
                        <label htmlFor="task_obj">Reactions</label>
                        <div id="arrowCustom"
                             onMouseDown = {(e) => e.preventDefault()}
                             onMouseUp   = {(e) => {
                                 let taskObjInput = document.querySelector('#task_obj input');
                                 menuIsOpenTask ? taskObjInput.blur() : taskObjInput.focus();
                             }}
                        >
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true"
                                 focusable="false" className="css-tj5bde-Svg">
                                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"/>
                            </svg>
                        </div>
                    </div>

                    <ErrorMessage name="task_time_out" component="div"/>
                    <div className="form-floating mt-3">
                        <Field min="1" className="form-control" id="task_time_out" type="number"
                               name="task_time_out"/>
                        <label htmlFor="task_time_out">Time Out</label>
                    </div>

                    <ErrorMessage name="count_action_per_timeout" component="div"/>
                    <div className="form-floating mt-3">
                        <Field min="1" className="form-control" id="count_action_per_timeout" type="number"
                               name="count_action_per_timeout"/>
                        <label htmlFor="count_action_per_timeout">Count action per timeout</label>
                    </div>

                    <ErrorMessage name="percetn_wave" component="div"/>
                    <div className="form-floating mt-3">
                        <Field max="100" min="0" className="form-control" id="percetn_wave" type="number"
                               name="percetn_wave"/>
                        <label htmlFor="percetn_wave">Wave %</label>
                    </div>

                    <ErrorMessage name="percent_markup_spread" component="div"/>
                    <div className="form-floating mt-3">
                        <Field max="100" min="0" className="form-control" id="percent_markup_spread" type="number"
                               name="percent_markup_spread"/>
                        <label htmlFor="percent_markup_spread">Markup Spread %</label>
                    </div>

                    <button className="btn btn-primary w-50 mt-3 py-2" type="submit"
                            disabled={isSubmitting}>
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default AddTaskFormik;