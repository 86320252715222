import React, {useState, useEffect} from 'react';
import {Navigate} from "react-router-dom";
import {cfg} from "../../cfg";

export default function SignUpApi(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    // Примечание: пустой массив зависимостей [] означает, что
    // этот useEffect будет запущен один раз аналогично componentDidMount()
    useEffect(() => {
        fetch(
            cfg.registrationUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: props.name,
                    email: props.email,
                    password: props.password
                })
            }
        )
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setData(result);
            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, []);

    async function onSignUp() {
        await new Promise(() => {
            props.onSignUp({
                token: data.token,
                role: 'user',
                isLogged: true
            });
        })
    }

    if (error) {
        return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        return (<h6 className="text-center">Загрузка...</h6>);
    } else {
        if (data.status === 'success' || data.status === 'ok') {
            onSignUp(data).then(() => {
                return <Navigate to="/dashboard" />;
            });
        }

        return (<>{data.status}: {data.message}</>);
    }
}
