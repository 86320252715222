import React from 'react';
import logo from '../img/logo512.png';
import SignUpApi from "./api/SignUpApi";
import { Formik, Form, Field, ErrorMessage }  from 'formik';

export default function SignUp({ onSignUp, setMsg }) {
    function signUpByApi(name, email, password) {
        setMsg(<SignUpApi onSignUp={onSignUp} name={name} email={email} password={password}/>);
    }

    return (
        <main className="form-signin w-100 m-auto bg-body-tertiary">
            <Formik
                initialValues={{name: '' ,email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.name) errors.name = 'Обязательное поле.';
                    if (!values.password) errors.password = 'Обязательное поле.';
                    if (!values.email) {
                        errors.email = 'Обязательное поле.';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        signUpByApi(values.name, values.email,values.password);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <img className="mb-4" src={logo} alt="logo" width="70" height="70" />
                        <ErrorMessage name="name" component="div" />
                        <div className="form-floating">
                            <Field className="form-control" id="floatingName" type="name" name="name" />
                            <label htmlFor="floatingName">Name</label>
                        </div>

                        <ErrorMessage name="email" component="div" />
                        <div className="form-floating">
                            <Field className="form-control" id="floatingInput" type="email" name="email" />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>

                        <ErrorMessage name="password" component="div" />
                        <div className="form-floating">
                            <Field className="form-control" id="floatingPassword" type="password" name="password" />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>

                        <div className="form-check text-start my-3">
                            <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Remember me
                            </label>
                        </div>

                        <button className="btn btn-primary w-100 py-2" type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>

        </main>
    );
}
