import {banStatus} from "../../staticData/UI_Data";
import React from "react";

const GetSessionListData = (props) => {
    let inputCheckedVal = props.listCode + 'Array';
    let renderList = props.list.map((item, i) => {
        return (
            <tr key={i + '_' + props.listCode}>
                <td className="pe-1">
                    <input className="cursor-pointer form-check-input me-2"
                       type="checkbox"
                       table-item={props.tableItem}
                       value={item.id}
                       id={`${props.listCode}-${item.id}`}
                       onChange={(item) => {
                            let htmlDataArr = document.getElementById(inputCheckedVal);
                            let newArr = htmlDataArr.value !== '' ? JSON.parse(htmlDataArr.value) : '';
                            if (item.target.checked) {
                               newArr.push(item.target.value * 1);
                            } else {
                                newArr.splice(newArr.indexOf(item.target.value * 1), 1);
                            }

                            htmlDataArr.value = JSON.stringify(newArr);
                            document.getElementById(`${props.listCode}CheckedCount`).textContent = newArr.length;

                            let checkboxAllSelected = document.getElementById(props.tableBy);
                            let toastListMA = document.getElementById(props.toastListMA);
                            if (newArr.length === 0 ) {
                               checkboxAllSelected.checked = false;
                               checkboxAllSelected.indeterminate = false;
                               toastListMA.classList.add('hide');
                               toastListMA.classList.remove('show');
                            } else {
                               toastListMA.classList.add('show');
                               toastListMA.classList.remove('hide');
                               if (newArr.length === props.data.sessions.length) {
                                   checkboxAllSelected.checked = true;
                               } else {
                                   checkboxAllSelected.indeterminate = true;
                               }
                            }
                        }}
                    />
                </td>
                <td>
                    <span className="cursor-pointer ms-2 me-4"
                          data-bs-toggle="modal"
                          data-bs-target={`#${props.modalIdForEditItems}`}
                          onClick={() => {
                              props.setEditItem(item);
                          }}
                    >
                        <svg className="bi bi-pencil-square" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>
                    </span>
                </td>
                <td scope="row"><span className="">#{item.id}</span></td>
                <td style={{color: banStatus[item.ban].color}}>
                    {banStatus[item.ban].text}
                </td>
                <td>{item.tagName}</td>
                <td>{item.group}</td>
                <td>{item.category}</td>
                <td>{item.dateAdd ?? 'empty'}</td>
            </tr>
        );
    });

    return (
        <>
            <tr className="d-none">
                <td>
                    <input className="d-none" id={inputCheckedVal} type="text" defaultValue="[]"/>
                </td>
            </tr>
            {renderList}
        </>
    );
};

export default GetSessionListData;