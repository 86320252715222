import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg.js";

export default function AddTaskApi(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(cfg.addTaskApiUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token,
                    task_type: props.values.task_type,
                    task_target: props.values.task_target,
                    group: props.values.group,
                    task_count_actions: props.values.task_count_actions * 1,
                    task_obj: props.values.task_obj,
                    task_time_out: props.values.task_time_out * 1,
                    task_auto: props.values.task_auto,
                    task_channel_id: props.values.task_channel_id,
                    count_action_per_timeout: props.values.count_action_per_timeout * 1,
                    percetn_wave: props.values.percetn_wave * 1,
                    percent_markup_spread: props.values.percent_markup_spread * 1,
                })
            }
        )
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setData(result);
            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [props.run]);

    if (error) {
        props.logs.push(JSON.stringify(error));
        return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        props.logs.push(JSON.stringify(data));
        if (data.status) return <div>{data.status}: {data.message}</div>;

        return <div>{data.detail[0].type ? data.detail[0].type + ' : ' : ''} {data.detail[0].msg} {data ? '' : 'см. логи' }</div>;
    }
}
