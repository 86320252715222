import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg";

function EditProxySessionApi(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    // Примечание: пустой массив зависимостей [] означает, что
    // этот useEffect будет запущен один раз, аналогично componentDidMount()
    useEffect(() => {
        fetchEditProxySessionApi(props);
        // debugger
    }, [props.run])

    function fetchEditProxySessionApi(props) {
        if (props.token && props.session.session_id && props.session.new_proxy) {
            fetch(cfg.editProxyAccountUrl,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        token: props.token,
                        session_id: props.session.session_id * 1,
                        new_proxy: props.session.new_proxy,
                    })
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setData(result);
                    },
                    // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                    // чтобы не перехватывать исключения из ошибок в самих компонентах.
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                );
        }
    }

    // debugger
    if (error) {
        return <div>#{props.session.session_id} - Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        // return <div>Загрузка...</div>;
    } else {
        if (data.status) return <div>#{props.session.session_id} - {data.status}: {data.message}</div>;

        return <div>{data.detail[0].type ? data.detail[0].type + ' : ' : ''} {data.detail[0].msg} {data ? '' : 'см. логи' }</div>;
    }
}

export default EditProxySessionApi;