import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg";
import SelectFilter, {filterList} from "../../ui/SelectFilter";
import GetTaskListData from "../../ui/Tables/GetTaskListData";

export default function GetTasksApi(props) {
    /** fetch data **/
    const [error, setError]       = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData]         = useState({data: []});
    /** main data **/
    const listCode = 'task';
    const [tasksBase, setTasksBase]       = useState([]);
    const [tasks, setTasks]               = useState([]);
    const [taskList, setTaskList]         = useState('');
    const [editTaskItem, setEditTaskItem] = useState({});
    /** sort & filter data **/
    const [sort, setSort]                 = useState('task_id');
    const [filterGroup, setFilterGroup]   = useState(cfg.DEFAULT_FILTERS_VAL);
    const [filterType, setFilterType]     = useState(cfg.DEFAULT_FILTERS_VAL);
    const [filterTarget, setFilterTarget] = useState(cfg.DEFAULT_FILTERS_VAL);
    /** checkbox data **/
    const [isMassChanged, setIsMassChanged] = useState(false);
    /** Counters **/
    const [allCount, setAllCount] = useState(0);
    const [subs, setSubs]         = useState(0);
    const [view, setView]         = useState(0);
    const [react, setReact]       = useState(0);
    const [active, setActive]     = useState(0);

    /** First render **/
    useEffect(() => {
        /** интервал обновления апи get-списка **/
        setInterval(async () => {
            if (cfg.IS_GET_LISTS_BY_INTERVAL) {
                setData({data: []});
                setTasks([]);
                await fetchTasksApi().then();
            }
        }, cfg.GET_LISTS_INTERVAL);
    }, []);

    /** Вызывается для обновления списка **/
    useEffect(() => {
        fetchTasksApi().then();
    }, [props.run]);

    // todo попробовать совместить дубли
    async function fetchTasksApi() {
        await fetch(cfg.getTasksApi,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token
                })
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    /** Сетит счетчики кол-ва данных **/
    function setCounters(list) {
        if (list[0]) {
            setAllCount (list.length);
            setSubs     (list.filter(item => item.task_type === 'subs').length);
            setView     (list.filter(item => item.task_type === 'view').length);
            setReact    (list.filter(item => item.task_type === 'react').length);
            setActive   (list.filter(item => item.active === 0).length);
        }
    }

    // todo сделать совместную фильтрацию
    /** Переключатель фильтров **/
    function resetFilters(curFilter) {
        if (curFilter === 'type') {
            setFilterGroup  (cfg.DEFAULT_FILTERS_VAL);
            setFilterTarget (cfg.DEFAULT_FILTERS_VAL);
        } else if (curFilter === 'group') {
            setFilterType   (cfg.DEFAULT_FILTERS_VAL);
            setFilterTarget (cfg.DEFAULT_FILTERS_VAL);
        } else if (curFilter === 'target') {
            setFilterType  (cfg.DEFAULT_FILTERS_VAL);
            setFilterGroup (cfg.DEFAULT_FILTERS_VAL);
        }
    }

    // todo убрать дубль кода
    /** Сортиврока **/
    function sortTasks(sort, tasks) {
        let newList = [];
        let sortBy  = 'DESC';
        let sortTaskList = document.getElementById('sortTaskList');
        if (sortTaskList) sortBy = sortTaskList.getAttribute('sort-by');

        if (['task_id','active','task_count_actions','task_count_actions_compite'].includes(sort)) {
            newList = [...tasks].sort((a,b) => {
                if (sortBy === 'ASC') return a[sort] - b[sort];
                return b[sort] - a[sort];
            });
        } else {
            newList = [...tasks].sort((a,b) => a[sort].localeCompare(b[sort]));
            if (sortBy === 'DESC') newList = newList.reverse();
        }

        setTasks(newList);
        setTaskList(getTasksList(newList));
    }

    // todo убрать дубль кода
    /** Итоговый рендер (есть html дубли) **/
    function getTasksList(newData) {
        return (
            <>
                <div style={{top: '-40px'}} className="toast fade position-absolute bg-white text-bg-light hide"
                     id="MA-task"
                     role="alert"
                     aria-live="assertive"
                     aria-atomic="true"
                >
                    <div className="toast-header">
                        <svg className="bd-placeholder-img rounded me-2" width="20" height="20"
                             xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                             preserveAspectRatio="xMidYMid slice" focusable="false">
                            <rect width="100%" height="100%" fill="#007aff"/>
                        </svg>
                        <strong className="me-auto">Mass Actions: </strong>
                        <small className="text-body-secondary">
                            selected: <span id={`${listCode}CheckedCount`}>0</span> of {data.data.length}
                        </small>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"/>
                    </div>
                    <div className="toast-body">
                        <button disabled=""
                                className="btn btn-link text-black"
                                data-bs-toggle="modal"
                                data-bs-target="#editTask"
                                onClick={e => setIsMassChanged(true)}
                        >
                            <svg className="bi bi-pencil-square me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>
                            Edit tasks
                        </button>
                    </div>
                </div>


                <table table-by="selectTask" className="table table-striped d-block overflow-auto"
                       style={{maxHeight: '250px'}}>
                    <thead>
                        <tr>
                            <th className="pe-1">
                                <input className="cursor-pointer form-check-input"
                                       type="checkbox"
                                       id="selectTask"
                                       onChange={(e) => {
                                           let checkboxlist = document.querySelectorAll('[table-by="selectTask"] [table-item="checkboxTask"]');
                                           let MA_task = document.getElementById('MA-task');
                                           let htmlDataArr = document.getElementById(`${listCode}Array`);

                                           if (e.target.checked) {
                                               MA_task.classList.add('show');
                                               MA_task.classList.remove('hide');
                                               let newArr = [];
                                               Object.keys(checkboxlist).forEach((item, i) => {
                                                   checkboxlist[i].checked = true;
                                                   newArr.push(data.data[i].task_id);
                                               })
                                               document.getElementById(`${listCode}CheckedCount`).textContent = newArr.length;
                                           } else {
                                               htmlDataArr.value = '[]';
                                               MA_task.classList.add('hide');
                                               MA_task.classList.remove('show');
                                               Object.keys(checkboxlist).forEach((item, i) => {
                                                   checkboxlist[i].checked = false;
                                               })
                                               document.getElementById(`${listCode}CheckedCount`).textContent = 0;
                                           }
                                       }}
                                />
                            </th>
                            <th>
                                <label className="cursor-pointer" htmlFor="selectTask">All</label>
                            </th>
                            <th>#ID</th>
                            <th>Id chan.</th>
                            <th>Actions</th>
                            <th style={{minWidth: '100px'}}>Time out</th>
                            <th style={{minWidth: '110px'}}>Per timeout</th>
                            <th>Wave</th>
                            <th style={{minWidth: '100px'}}>Markup %</th>
                            <th className="col-2">Reactions</th>
                            <th>Target</th>
                            <th>Group</th>
                            <th>Type</th>
                            <th>Date</th>
                            {/*<th scope="col">ROI</th>*/}
                            {/*<th scope="col" style="min-width: 100px;">Size Coins</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        <GetTaskListData
                            listCode={listCode} // unic list code
                            tableBy="selectTask" // main "select all"
                            tableItem="checkboxTask" // items with checkbox
                            toastListMA="MA-task" // popup mass action
                            modalIdForEditItems="editTask" // modal
                            data={data} //fetch data
                            list={newData} // main arr list
                            setEditItem={setEditTaskItem} // if edit one item, set obj
                        />
                    </tbody>
                </table>
            </>
        );
    }

    async function editTask(task) {
        let htmlDataArr = document.getElementById(`${listCode}Array`);
        htmlDataArr = JSON.parse(htmlDataArr);
        let dataLength = htmlDataArr.length ?? 1;
        setTasks([]);
        setData({data: []});

        props.setMsg('Загрузка...');
        if (data[0])  setData([]);
        if (tasks[0]) setTasks([]);

        if (isMassChanged && htmlDataArr[0] && !task.edit_task_id) {
            await htmlDataArr.forEach((taskId, i) => {
                // props.logs.push(<EditAutoTaskApi
                //     logs={props.logs}
                //     token={props.token}
                //     task={{
                //         token : props.token,
                //         edit_task_id : taskId * 1,
                //         edit_task_auto : task.edit_task_auto ?? true,
                //         edit_task_channel_id : task.edit_task_channel_id,
                //         edit_task_type : task.edit_task_type,
                //         edit_task_count_actions : task.edit_task_count_actions,
                //         edit_task_obj : task.edit_task_obj,
                //         edit_task_time_out : task.edit_task_time_out,
                //         edit_count_action_per_timeout : task.edit_count_action_per_timeout,
                //         edit_percetn_wave : task.edit_percetn_wave,
                //         edit_percent_markup_spread : task.edit_percent_markup_spread,
                //         task_group : task.task_group,
                //     }}
                //     run={Math.random()}
                // />);
            })
        } else if (task.edit_task_id) {
            // props.logs.push(<EditAutoTaskApi
            //     logs={props.logs}
            //     token={props.token}
            //     task={task}
            //     run={Math.random()}
            // />);
        }

        setTimeout(() => {
            setIsMassChanged(false);
            props.setMsg('Готово, смотрите логи');
            fetchTasksApi().then();
        }, dataLength * 500);
    }

    /** Уникальный вывод селектов **/
    function getListHeader(list) {
        return (
            <div className="d-flex justify-content-between mb-3 flex-wrap flex-lg-nowrap">
                <div className="d-flex flex-wrap flex-lg-nowrap">
                    <div className="d-flex align-items-center badge bg-primary me-2 cursor-default">
                        <span className="h6 m-0">Всего: {allCount}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">subs: {subs}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">view: {view}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-success me-2 cursor-default">
                        <span className="h6 m-0">react: {react}</span>
                    </div>
                    <div className="d-flex align-items-center badge bg-purple me-2 cursor-default">
                        <span className="h6 m-0">
                            Active: {active}
                            <b> of </b> {list.length}
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-wrap flex-lg-nowrap mt-2 mt-lg-0">

                    <button name="updateList" className="btn btn-primary py-2 me-2"
                            style={{maxHeight: '250px'}}
                            onClick={(e) => {
                                if (!tasks[0]) return;
                                setData([]);
                                setTasks([]);
                                setTaskList(<h6 className="text-center">Загрузка...</h6>);

                                e.target.disabled = true;
                                setTimeout(() => {
                                    fetchTasksApi().then(() => {
                                        setTaskList(getTasksList(tasks));
                                        e.target.disabled = false;
                                    });
                                }, 100);
                            }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                    </button>

                    <div name="toggleList" sort-by="DESC" id="sortTaskList" className="btn btn-primary py-2 me-2"
                         style={{maxHeight: '250px'}}
                         onClick={(e) => {
                             if (!tasks[0]) return;
                             if (e.target.getAttribute('sort-by') === 'ASC') {
                                 e.target.setAttribute('sort-by', 'DESC');
                                 e.target.style.transform = 'rotate(180deg)';
                             } else {
                                 e.target.setAttribute('sort-by', 'ASC');
                                 e.target.style.transform = 'rotate(0deg)';
                             }

                             sortTasks(sort, tasks);
                         }}
                    >
                        <span style={{pointerEvents: 'none'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-sort-down-alt" viewBox="0 0 16 16">
                              <path d="M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z"/>
                            </svg>
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-wrap flex-lg-nowrap mt-2 mt-lg-0">
                    <SelectFilter
                        optType="target"
                        optTypePrefix="task_" // default - empty
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={tasks}
                        filter={filterTarget}
                        setFilter={setFilterTarget}
                        setFilterList={setTasks}
                        setCurList={setTaskList}
                        getCurList={getTasksList}
                        baseList={tasksBase}
                        resetFilters={resetFilters}
                        sort={sortTasks}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <SelectFilter
                        optType="group"
                        optTypePrefix="task_" // default - empty
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={tasks}
                        filter={filterGroup}
                        setFilter={setFilterGroup}
                        setFilterList={setTasks}
                        setCurList={setTaskList}
                        getCurList={getTasksList}
                        baseList={tasksBase}
                        resetFilters={resetFilters}
                        sort={sortTasks}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <SelectFilter
                        optType="type"
                        optTypePrefix="task_" // default - empty
                        defaultOption={<option disabled value="">Filter by:</option>}
                        options={tasks}
                        filter={filterType}
                        setFilter={setFilterType}
                        setFilterList={setTasks}
                        setCurList={setTaskList}
                        getCurList={getTasksList}
                        baseList={tasksBase}
                        resetFilters={resetFilters}
                        sort={sortTasks}
                        sortBy={sort}
                        setCounters={setCounters}
                    />

                    <select className="p-2" name="task-sort" id="task-sort"
                            style={{maxWidth: '130px'}}
                            value={sort}
                            onChange={e => {
                                setSort(e.target.value);
                                sortTasks(e.target.value, tasks);
                            }}
                    >
                        <option disabled value="">Сортировать по: </option>
                        <option value="task_id">Id</option>
                        <option value="task_id_channel">Id chanel</option>
                        <option value="task_count_actions">Count actions</option>
                        <option value="task_count_actions_compite">Count actions compite</option>
                        <option value="task_time_out">Time out</option>
                        <option value="task_obj">Reactions</option>
                        <option value="active">Active</option>
                        <option value="task_type">Target</option>
                        <option value="task_type">Type</option>
                    </select>
                </div>



                </div>
        )
    }

    if (error) {
        // todo ERROR
        props.logs.push(JSON.stringify(error));
        return (
            <div className="d-flex flex-column">
                <span>Что-то не так, обратитесь в тех. поддержку</span>
                <span>Код ошибки: {JSON.stringify(error.message)}</span>
            </div>
        );
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        if (['ok', 'succsess'].includes(data.status)) {
            if (!tasks[0] && data.data[0]) {
                data.data.reverse();
                setTasksBase(data.data);

                /** Фильтрация если выбраны условия **/
                let filterData = {};
                if (filterGroup  !== cfg.DEFAULT_FILTERS_VAL) filterData = {type: 'task_group', filter: filterGroup};
                if (filterType   !== cfg.DEFAULT_FILTERS_VAL) filterData = {type: 'task_type', filter: filterType};
                if (filterTarget !== cfg.DEFAULT_FILTERS_VAL) filterData = {type: 'task_target', filter: filterTarget};

                if (filterData.type && filterData.filter) {
                    data.data = filterList({
                        type: filterData.type,
                        filter: filterData.filter,
                        baseList: tasksBase ?? data.data,
                    });
                }

                // setTasks(data.data);            // возможно понадобится
                // setAccList(getAccList(data.data)); // возможно понадобится
                /** Сортировка, сетит setTasks и setTaskList **/
                sortTasks(sort, data.data);

                /** Счётчики кол-ва данных списка **/
                setCounters(data.data);
            }
        } else if (tasksBase[0]) {
            data.data = tasksBase;
        } else {
            return (
                <>
                    <div className="position-relative">
                        {getListHeader(data.data)}
                    </div>
                    <h6 className="text-center">Загрузка...</h6>
                </>
            );
        }

        return (
            <div className="position-relative">
                {getListHeader(data.data)}

                {data.data.length === 0 &&
                    <span className="text-center d-block">Нет записей.</span>
                }

                {data.data.length > 0 &&
                <>
                    {taskList}
                    {/* modal - edit task */}
                    <div className="modal fade" id="editTask" tabIndex="-1" aria-labelledby="editTask" style={{display: 'none'}} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">

                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalCenteredScrollableTitle">
                                        Edit All tasks
                                    </h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                                </div>
                                <div className="modal-body">
                                    <p>Just text..</p>
                                    <p>This content should appear at the bottom after you scroll.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary"
                                            data-bs-dismiss="modal">Close
                                    </button>
                                    <button type="button" className="btn btn-primary">Save changes</button>
                                </div>

                                {/*<EditAutoTaskFormik*/}
                                {/*    editItem={editTaskItem}*/}
                                {/*    token={props.token}*/}
                                {/*    editTask={editTask}*/}
                                {/*    task_obj={editTaskItem.task_obj}*/}
                                {/*/>*/}

                            </div>
                        </div>
                    </div>
                </>}
            </div>
        );
    }
}
