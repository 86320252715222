import React, {useEffect, useState} from 'react';
import {cfg} from '../../../cfg';

const SwitchAuto = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchSwitchAuto().then();
    }, [props.run]);

    async function fetchSwitchAuto() {
        await fetch(cfg.switchAutoApiUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token,
                    auto_task_id: props.autoTaskId
                })
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    if (error) {
        return <div>Ошибка: {JSON.stringify(error)}</div>;
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        if (data.status !== 'ok') {
            return <div>Ошибка: {JSON.stringify(data.message)}</div>;
        }

        return <div>Авто-задача #{props.autoTaskId}, {data.message}</div>;
    }
};

export default SwitchAuto;