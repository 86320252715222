export const taskReactions = [
    { value: "👍", label: "👍" },
    { value: "👎", label: "👎" },
    { value: "❤", label: "❤" },
    { value: "🔥", label: "🔥" },
    { value: "🥰", label: "🥰" },
    { value: "👏", label: "👏" },
    { value: "😁", label: "😁" },
    { value: "🤔", label: "🤔" },
    { value: "🤯", label: "🤯" },
    { value: "😱", label: "😱" },
    { value: "🤬", label: "🤬" },
    { value: "😢", label: "😢" },
    { value: "🎉", label: "🎉" },
    { value: "🤩", label: "🤩" },
    { value: "🤮", label: "🤮" },
    { value: "💩", label: "💩" },
    { value: "🙏", label: "🙏" },
    { value: "👌", label: "👌" },
    { value: "🕊", label: "🕊" },
    { value: "🤡", label: "🤡" },
    { value: "🥱", label: "🥱" },
    { value: "🥴", label: "🥴" },
    { value: "😍", label: "😍" },
    { value: "🐳", label: "🐳" },
    // { value: "❤🔥", label: "❤🔥" },
    { value: "🌚", label: "🌚" },
    { value: "🌭", label: "🌭" },
    { value: "💯", label: "💯" },
    { value: "🤣", label: "🤣" },
    { value: "⚡", label: "⚡" },
    { value: "🍌", label: "🍌" },
    { value: "🏆", label: "🏆" },
    { value: "💔", label: "💔" },
    { value: "🤨", label: "🤨" },
    { value: "😐", label: "😐" },
    { value: "🍓", label: "🍓" },
    { value: "🍾", label: "🍾" },
    { value: "💋", label: "💋" },
    { value: "🖕", label: "🖕" },
    { value: "😈", label: "😈" },
    { value: "😴", label: "😴" },
    { value: "😭", label: "😭" },
    { value: "🤓", label: "🤓" },
    { value: "👻", label: "👻" },
    // { value: "👨💻", label: "👨💻" },
    { value: "👀", label: "👀" },
    { value: "🎃", label: "🎃" },
    { value: "🙈", label: "🙈" },
    { value: "😇", label: "😇" },
    { value: "😨", label: "😨" },
    { value: "🤝", label: "🤝" },
    { value: "✍", label: "✍" },
    { value: "🤗", label: "🤗" },
    { value: "🎅", label: "🎅" },
    { value: "🎄", label: "🎄" },
    { value: "☃", label: "☃" },
    { value: "💅", label: "💅" },
    { value: "🤪", label: "🤪" },
    { value: "🗿", label: "🗿" },
    { value: "🆒", label: "🆒" },
    { value: "💘", label: "💘" },
    { value: "🙉", label: "🙉" },
    { value: "🦄", label: "🦄" },
    { value: "😘", label: "😘" },
    { value: "💊", label: "💊" },
    { value: "🙊", label: "🙊" },
    { value: "😎", label: "😎" },
    { value: "👾", label: "👾" },
    // { value: "🤷♂", label: "🤷♂" },
    { value: "🤷", label: "🤷" },
    // { value: "🤷♀", label: "🤷♀" },
    { value: "😡", label: "😡" },
];
