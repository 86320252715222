import React, {useState, useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import {cfg} from "../../cfg";

export default function SendMessageApi(props) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch(cfg.sendMessageTelegramUserUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token,
                    session_id: props.session_id,
                    chat_id: props.chat_id,
                    message: props.message
                })
            }
        )
        .then(res => res.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setData(result);
            },
            // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
            // чтобы не перехватывать исключения из ошибок в самих компонентах.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, [props.run])

    if (error) {
        return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Загрузка...</div>;
    } else {
        // if (data.status === 'success') {
        // }

        console.log(data);
        if (data.status && data.message) {
            return <div>{data.status}: {data.message}</div>;
        }
    }
}
