import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
// Components:
import Logs from "../components/ui/Logs";
// Tasks
import AddTaskApi from "../components/api/Tasks/AddTaskApi";
import GetTasksApi from "../components/api/Tasks/GetTasksApi";
import GetTasksAutoApi from "../components/api/Tasks/GetTasksAutoApi";
import SendMessageApi from "../components/api/SendMessageApi"; // testing
import AddTaskFormik from "../components/ui/Dashboard/Tasks/AddTaskFormik";
// Accaunts & Sessions
import AddAccountApi from "../components/api/Sessions/AddAccountApi";
import GetSessionsApi from "../components/api/Sessions/GetSessionsApi";
import GetAllSessionsApi from "../components/api/Sessions/GetAllSessionsApi";
import DeleteSessionApi from "../components/api/Sessions/DeleteSessionApi";
import AddTaskFormikModal from "../components/ui/Dashboard/Tasks/AddTaskFormikModal";

export default function Dashboard(props) {
    // base
    const [addAccauntGroup, setAddAccauntGroup]       = useState(null);
    const [addAccauntCategory, setAddAccauntCategory] = useState(null);
    const [addAccauntVal, setAddAccauntVal]           = useState(0);
    const [addAccauntFiles, setAddAccauntFiles]       = useState([]);
    const [addAccauntProxy, setAddAccauntProxy]       = useState({data: [], skip: []});
    const [isRenderSession, setRenderSession]         = useState(false);

    useEffect(() => {
        props.setMsg('Здесь отобразится статус апи ответа - отправьте любой апи запрос.');
    }, []);

    /* Custom array for add task (smiles) */
    useEffect(() => {
        let arrow = document.querySelector('#task_obj > div > div:nth-child(2) > div[class$="-indicatorContainer"]:last-child');
        let arrowContainer = document.querySelector('#task_obj > div > div:nth-child(2)');
        if (arrow && arrowContainer && !arrow.style.display) {
            arrow.style.display = 'none';
            let arrowCustom = document.getElementById('arrowCustom');
            arrowContainer.appendChild(arrowCustom);
        }
    });

    function sendMessage(token, session_id, chat_id, message) {
        props.setMsg(<SendMessageApi
            token={token} session_id={session_id} chat_id={chat_id} message={message} run={Math.random()}
        />);
    }

    function addAccount() {
        props.updateList    (<h6 className="text-center">Загрузка...</h6>);
        props.updateListAll (<h6 className="text-center">Загрузка...</h6>);
        props.setMsg        ('Загрузка..');

        const files = document.getElementById("addAccountFiles").files;
        setAddAccauntFiles(files);

        const proxy   = document.getElementById("addAccountProxy").files[0];
        const reader  = new FileReader();
        reader.onload = (eproxy) => {
            setAddAccauntProxy({data : reader.result.split('\n'), skip : []});
        };

        props.setLogs([]);
        reader.readAsText(proxy);
        setRenderSession(true);
    }

    async function asyncAddAccount(i, step, len) {
        let curProxy = addAccauntProxy.skip.length;
        if (addAccauntProxy.data.length > curProxy) {
            if ((i+1) % 5 === 0 && i !== 0) {
                addAccauntProxy.skip.push(addAccauntProxy.data[curProxy]);
            }

            let formData = new FormData();
            formData.append('token', props.token);
            formData.append('group', addAccauntGroup);
            formData.append('category', addAccauntCategory);
            formData.append('file',  addAccauntFiles[i]);
            formData.append('proxy', addAccauntProxy.data[curProxy]);

            let log = await AddAccountApi(
                formData,
                1*i+1,
                addAccauntProxy.data[curProxy],
                addAccauntGroup,
                addAccauntCategory
            );
            let progress = await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(step * (i+1));
                }, 1000 * (i + 1));
            });

            progress = progress.toFixed(1);
            progress = progress + 2 >= 100 ? 100 : progress;

            if (addAccauntVal < progress || addAccauntVal === 100 ) setAddAccauntVal(progress);

            return {log: log, curIteration: i, progress: progress};
        } else {
            /** Вызывается 1 раз, в цикле map есть лимит "count" **/
            props.setMsg(<>
                <span>Не достаточно прокси для добавления всех сессий.</span>
                <span>Добавлено {i} из {len} сессий.</span>
            </>);
            setRenderSession   (false);
            setAddAccauntProxy ({data : [], skip : []});
            props.updateList(<GetSessionsApi
                token={props.token}
                setLogs={props.setLogs}
                setTaskCountActionsLimit={props.setTaskCountActionsLimit}
                setMsg={props.setMsg}
                setSessionsGroup={props.setSessionsGroup}
                run={Math.random()}
            />);
            props.updateListAll(<GetAllSessionsApi
                token={props.token}
                setLogs={props.setLogs}
                userRole={props.userRole}
                setTaskCountActionsLimit={props.setTaskCountActionsLimit}
                setMsg={props.setMsg}
                setSessionsGroupAdmin={props.setSessionsGroupAdmin}
                run={Math.random()}
            />);
        }
    }

    function delAccount(token, account_id) {
        props.setMsg(<DeleteSessionApi
            token={token} account_id={account_id} run={Math.random()}
        />);
        props.updateList    (<h6 className="text-center">Загрузка...</h6>);
        props.updateListAll (<h6 className="text-center">Загрузка...</h6>);
        setTimeout(() => {
            props.updateList(<GetSessionsApi
                token={token}
                setLogs={props.setLogs}
                setTaskCountActionsLimit={props.setTaskCountActionsLimit}
                setMsg={props.setMsg}
                setSessionsGroup={props.setSessionsGroup}
                run={Math.random()}
            />);
            props.updateListAll(<GetAllSessionsApi
                token={token}
                setLogs={props.setLogs}
                userRole={props.userRole}
                setTaskCountActionsLimit={props.setTaskCountActionsLimit}
                setMsg={props.setMsg}
                setSessionsGroupAdmin={props.setSessionsGroupAdmin}
                run={Math.random()}
            />);
        }, 500);
    }

    function addTask(token, values) {
        props.updateTasks     (<h6 className="text-center">Загрузка...</h6>);
        props.updateTasksAuto (<h6 className="text-center">Загрузка...</h6>);
        props.setMsg(<AddTaskApi
            logs={props.logs}
            token={token}
            values={values}
            run={Math.random()}
        />);

        setTimeout(() =>  {
            props.updateTasks(<GetTasksApi token={token} run={Math.random()} />);
            props.updateTasksAuto(<GetTasksAutoApi
                logs={props.logs}
                token={token}
                setMsg={props.setMsg}
                updateTasks={props.updateTasks}
                curUnicGroups={props.curUnicGroups}
                run={Math.random()}
            />);
        }, 500);
    }

    if (!props.isLogged) {
        return (<>
            <h2>Error</h2>
            <span>Please, login before using this page.</span>
        </>);
    } else return (
        <>
            <Logs logs={props.logs} />
            <h3 className="display-5 fw-bold">Dashboard</h3>

            <div className="accordion mb-3" id="session">
                <div className="accordion-item">
                    <h4 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#sessionOne" aria-expanded="false" aria-controls="sessionOne">
                            Sessions
                        </button>
                    </h4>
                    <div id="sessionOne" className="accordion-collapse collapse" data-bs-parent="#session" >
                        <div className="accordion-body">

                            <div className="accordion mb-3" id="accForms">
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseAccForms" aria-expanded="false" aria-controls="collapseAccForms">
                                            Forms
                                        </button>
                                    </h4>
                                    <div id="collapseAccForms" className="accordion-collapse collapse" data-bs-parent="#accForms" >
                                        <div className="accordion-body">

                                            <div className="row my-3 justify-content-between">
                                                <div className="col-12 col-md-6 col-xl-5 d-flex flex-column">
                                                    <h4>Add Account</h4>
                                                    <Formik
                                                        initialValues={{group: 'default', category: 'default', proxy: '', files: ''}}
                                                        validate={values => {
                                                            const errors = {};
                                                            if (!values.group) errors.group = 'Обязательное поле.';
                                                            if (!values.category) errors.category = 'Обязательное поле.';
                                                            if (!values.proxy) errors.proxy = 'Обязательное поле.';
                                                            if (!values.files) errors.files = 'Обязательное поле.';
                                                            return errors;
                                                        }}
                                                        onSubmit={(values, {setSubmitting}) => {
                                                            setTimeout(() => {
                                                                setSubmitting(false);
                                                                setAddAccauntGroup(values.group);
                                                                setAddAccauntCategory(values.category);
                                                                addAccount();
                                                            }, 400);
                                                        }}
                                                    >
                                                        {({isSubmitting}) => (
                                                            <Form>
                                                                <ErrorMessage name="category" component="div"/>
                                                                <div className="form-floating mt-3">
                                                                    <Field className="form-control" id="category" type="text" name="category"/>
                                                                    <label htmlFor="category">Company</label>
                                                                </div>

                                                                <ErrorMessage name="group" component="div"/>
                                                                <div className="form-floating mt-3">
                                                                    <Field className="form-control" id="group" type="text" name="group"/>
                                                                    <label htmlFor="group">Group</label>
                                                                </div>

                                                                <label className="mb-2 mt-3" htmlFor="proxy">Один файл с proxy ( *.txt )</label>
                                                                <ErrorMessage name="proxy" component="div"/>
                                                                <Field className="form-control" accept=".txt" type="file" name="proxy" id="addAccountProxy" />

                                                                <label className="mb-2 mt-3" htmlFor="files">Файлы сессий ( *.session )</label>
                                                                <ErrorMessage name="files" component="div"/>
                                                                <Field multiple
                                                                       className="form-control"
                                                                       type="file"
                                                                       name="files"
                                                                       id="addAccountFiles"
                                                                       accept=".session"
                                                                />

                                                                <button className="btn btn-primary w-50 mt-3 py-2" type="submit" disabled={isSubmitting}>
                                                                    Submit
                                                                </button>
                                                            </Form>
                                                        )}
                                                    </Formik>

                                                    { isRenderSession &&
                                                      addAccauntFiles.length > 0 &&
                                                      addAccauntProxy.data.length > 0  ? (
                                                        <>
                                                            {setRenderSession(false)}
                                                            {setAddAccauntVal(0)}

                                                            {Object.keys(addAccauntFiles).map(
                                                                // TODO replace map on classic function + foreach
                                                                function uploadSessions(i) {
                                                                    /* getting max iteration count */
                                                                    let count = addAccauntProxy.data.length * 5 + 1;
                                                                    i = i*1;
                                                                    if (!isNaN(i) && count >= i+1) {
                                                                        const length = addAccauntFiles.length;
                                                                        const step = (100 / length).toFixed(1);
                                                                        asyncAddAccount(i, step, length).then(result => {
                                                                            props.logs.push(JSON.stringify(result.log));
                                                                            if (result.curIteration) {
                                                                                if (result.progress >= 100 || length === i+1) {
                                                                                    props.setMsg('Добавление выполнено! Смотрите логи.');
                                                                                    setAddAccauntProxy({data : [], skip : []});
                                                                                    setAddAccauntGroup('');
                                                                                    setAddAccauntCategory('');
                                                                                    props.updateList(<GetSessionsApi
                                                                                        token={props.token}
                                                                                        setLogs={props.setLogs}
                                                                                        setTaskCountActionsLimit={props.setTaskCountActionsLimit}
                                                                                        setMsg={props.setMsg}
                                                                                        setSessionsGroup={props.setSessionsGroup}
                                                                                        run={Math.random()}
                                                                                    />);
                                                                                    props.updateListAll(<GetAllSessionsApi
                                                                                        token={props.token}
                                                                                        setLogs={props.setLogs}
                                                                                        userRole={props.userRole}
                                                                                        setTaskCountActionsLimit={props.setTaskCountActionsLimit}
                                                                                        setMsg={props.setMsg}
                                                                                        setSessionsGroupAdmin={props.setSessionsGroupAdmin}
                                                                                        run={Math.random()}
                                                                                    />);
                                                                                } else {
                                                                                    let timer = (2 * (length - result.curIteration)) / 60;
                                                                                    props.setMsg('Загрузка.. Осталось ~' + Math.ceil(timer) + ' мин.' );
                                                                                }
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            )}
                                                        </>
                                                    ) : (
                                                        <div className="mt-4">
                                                            <div className="progress mb-3" role="progressbar"
                                                                 aria-label="Info example with label"
                                                                 aria-valuenow={addAccauntVal}
                                                                 aria-valuemin="0" aria-valuemax="100">
                                                                <div style={{'width': addAccauntVal + '%'}} className="progress-bar text-bg-info fw-bold">{addAccauntVal}%</div>
                                                            </div>
                                                        </div>
                                                    )
                                                    }

                                                </div>

                                                <div style={{position: 'relative'}} className="col-12 col-md-6 col-xl-5 d-flex flex-column mt-4 mt-md-0">
                                                    <div style={{position: "sticky", top: '100px'}}>
                                                        <h4>Delete Account</h4>

                                                        <Formik
                                                            initialValues={{account_id: ''}}
                                                            validate={values => {
                                                                const errors = {};
                                                                if (!values.account_id) errors.account_id = 'Обязательное поле.';
                                                                return errors;
                                                            }}
                                                            onSubmit={(values, {setSubmitting}) => {
                                                                setTimeout(() => {
                                                                    delAccount(props.token, values.account_id);
                                                                    setSubmitting(false);
                                                                }, 400);
                                                            }}
                                                        >
                                                            {({isSubmitting}) => (
                                                                <Form>
                                                                    <ErrorMessage name="account_id" component="div"/>
                                                                    <div className="form-floating">
                                                                        <Field className="form-control" id="account_id" type="text"
                                                                               name="account_id"/>
                                                                        <label htmlFor="account_id">Account id</label>
                                                                    </div>

                                                                    <button className="btn btn-primary w-50 mt-3 py-2" type="submit"
                                                                            disabled={isSubmitting}>
                                                                        Submit
                                                                    </button>
                                                                </Form>
                                                            )}
                                                        </Formik>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <h4 className="mt-2 text-center mt-5 mb-4">My account List</h4>
                                {props.list}
                            </div>

                            {props.userRole === 'admin' &&
                                <div className="row">
                                    <h4 className="mt-2 text-center mt-5 mb-4">All Account List</h4>
                                    {props.listAll}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion mb-3" id="task">
                <div className="accordion-item">
                    <h4 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#taskOne" aria-expanded="false" aria-controls="taskOne">
                            Task Data
                        </button>
                    </h4>
                    <div id="taskOne" className="accordion-collapse collapse" data-bs-parent="#task" >
                        <div className="accordion-body">

                            <div className="row">

                                <h4>Add task</h4>
                                <div className="accordion mb-3" id="addTask">
                                    <div className="accordion-item">
                                        <h4 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseAddTask" aria-expanded="false" aria-controls="collapseAddTask">
                                                Forms OLD
                                            </button>
                                        </h4>
                                        <div id="collapseAddTask" className="accordion-collapse collapse" data-bs-parent="#addTask" >
                                            <div className="accordion-body">
                                                <AddTaskFormik
                                                    token={props.token}
                                                    addTask={addTask}
                                                    taskCountActionsLimit={props.taskCountActionsLimit}
                                                    groupList={props.curUnicGroups}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#addTaskModal">
                                    Add Task (Multi Step Mode)
                                </button>

                                <AddTaskFormikModal
                                    token={props.token}
                                    addTask={addTask}
                                    taskCountActionsLimit={props.taskCountActionsLimit}
                                    groupList={props.curUnicGroups}
                                    setMsg={props.setMsg}
                                />

                            </div>
                            <div className="row mt-3">
                                <h4 className="text-center">Tasks</h4>
                                {props.tasks}
                            </div>

                            <div className="row mt-3">
                                <h4 className="text-center mt-5">Tasks with type auto</h4>
                                {props.tasksAuto}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion mb-3" id="message">
                <div className="accordion-item">
                    <h4 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#messageOne" aria-expanded="false" aria-controls="messageOne">
                            Send message (testing)
                        </button>
                    </h4>
                    <div id="messageOne" className="accordion-collapse collapse" data-bs-parent="#message" >
                        <div className="accordion-body">

                            <Formik
                                initialValues={{session_id: '', chat_id: '', message: ''}}
                                validate={values => {
                                    const errors = {};
                                    if (!values.session_id) errors.session_id = 'Обязательное поле.';
                                    if (!values.chat_id)    errors.chat_id    = 'Обязательное поле.';
                                    if (!values.message)    errors.message    = 'Обязательное поле.';
                                    return errors;
                                }}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                        sendMessage(props.token, values.session_id, values.chat_id, values.message)
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({isSubmitting}) => (
                                    <Form>
                                        <ErrorMessage name="session_id" component="div"/>
                                        <div className="form-floating mt-3">
                                            <Field className="form-control" id="session_id" type="text" name="session_id"/>
                                            <label htmlFor="session_id">Session id</label>
                                        </div>

                                        <ErrorMessage name="chat_id" component="div"/>
                                        <div className="form-floating mt-3">
                                            <Field className="form-control" id="chat_id" type="text" name="chat_id"/>
                                            <label htmlFor="chat_id">Chat id</label>
                                        </div>

                                        <ErrorMessage name="message" component="div"/>
                                        <div className="form-floating mt-3">
                                            <Field className="form-control" id="message" type="text" name="message"/>
                                            <label htmlFor="message">Message</label>
                                        </div>

                                        <button className="btn btn-primary w-50 mt-3 py-2" type="submit"
                                                disabled={isSubmitting}>
                                            Submit
                                        </button>
                                    </Form>
                                )}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
