import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import {taskReactions} from "../../../staticData/TaskReactions";
import {ErrorMessage, Field, Form, Formik} from "formik";

const EditAutoTaskFormik = (props) => {
    const [menuIsOpenTask, setMenuIsOpenTask] = useState(false);
    // form data
    const [taskType, setTaskType]                      = useState('subs');
    const [task_id_channel, set_task_id_channel]       = useState('');
    const [task_count_actions, set_task_count_actions] = useState(1);
    const [task_obj, set_task_obj]                     = useState([]);
    const [task_time_out, set_task_time_out]           = useState(1);
    const [edit_percetn_wave, set_edit_percetn_wave]   = useState(0);
    const [task_group, set_task_group]                 = useState('all');
    const [edit_percent_markup_spread, set_edit_percent_markup_spread]       = useState(0);
    const [edit_count_action_per_timeout, set_edit_count_action_per_timeout] = useState(1);


    useEffect(() => {
        if (props.editItem.task_obj) {
            setTaskType                       (props.editItem.task_type);
            set_task_id_channel               (props.editItem.task_id_channel);
            set_task_count_actions            (props.editItem.task_count_actions * 1);
            set_task_time_out                 (props.editItem.task_time_out * 1);
            set_edit_count_action_per_timeout (props.editItem.count_action_per_timeout * 1);
            set_edit_percetn_wave             (props.editItem.percetn_wave * 1);
            set_edit_percetn_wave             (props.editItem.percetn_wave * 1);
            set_edit_percent_markup_spread    (props.editItem.percent_markup_spread * 1);

            let curReactions = [];
            JSON.parse(props.editItem.task_obj)
                .forEach((e) => curReactions.push({ value: e, label: e }) );
            set_task_obj(curReactions);
        }
    }, [props.editItem]);

    /** Custom array for edit task (smiles) **/
    useEffect(() => {
        let arrow = document.querySelector('#edit_task_obj > div > div:nth-child(2) > div[class$="-indicatorContainer"]:last-child');
        let arrowContainer = document.querySelector('#edit_task_obj > div > div:nth-child(2)');
        if (arrow && arrowContainer && !arrow.style.display) {
            arrow.style.display = 'none';
            let arrowCustom = document.getElementById('edit_arrowCustom');
            arrowContainer.appendChild(arrowCustom);
        }
    });

    function getGroupList() {
        let options = []
        props.groupList.map((e) => {
            options.push(<option key={e} value={e}>{e}</option>);
        });

        return options;
    }

    return (
        <>
            <Formik
                /** default values sets by global values ^ **/
                initialValues={{
                    edit_task_channel_id: '',
                    edit_task_type: 'subs',
                    edit_task_count_actions: 1,
                    edit_task_obj: [],
                    edit_task_time_out: 1,
                    edit_count_action_per_timeout: 1,
                    edit_percetn_wave: 0,
                    edit_percent_markup_spread: 0,
                    edit_task_group: 'all',
                }}
                validate={values => {
                    const errors = {};
                    let taskType = document.getElementById('edit_task_type').value;
                    let taskChannelId = document.getElementById('edit_task_channel_id').value;
                    let taskCountAction = document.getElementById('edit_task_count_actions').value;
                    let taskToimeOut = document.getElementById('edit_task_time_out').value;
                    let countActionPerTimeout = document.getElementById('edit_count_action_per_timeout').value;
                    let percetnWave = document.getElementById('edit_percetn_wave').value;
                    let editPercentMarkupSpread = document.getElementById('edit_percent_markup_spread').value;
                    let editTaskGroup = document.getElementById('edit_task_group').value;

                    let curReactions = [];
                    task_obj.forEach((e) => curReactions.push(e.value) );

                    values.edit_task_obj = curReactions;
                    values.edit_task_type = taskType;
                    values.edit_task_count_actions = taskCountAction * 1;
                    values.edit_task_time_out = taskToimeOut * 1;
                    values.edit_task_channel_id = taskChannelId * 1;
                    values.edit_count_action_per_timeout = countActionPerTimeout * 1;
                    values.edit_percetn_wave = percetnWave * 1;
                    values.edit_percent_markup_spread = editPercentMarkupSpread * 1;
                    values.edit_task_group = editTaskGroup;

                    if (!values.edit_task_type)                   errors.edit_task_type = 'Обязательное поле.';
                    if (!values.edit_task_count_actions)          errors.edit_task_count_actions = 'Обязательное поле.';
                    if (!values.edit_task_time_out)               errors.edit_task_time_out = 'Обязательное поле.';
                    if (!values.edit_count_action_per_timeout)    errors.edit_count_action_per_timeout = 'Обязательное поле.';
                    if (!values.edit_task_group)                  errors.edit_task_group = 'Обязательное поле.';
                    if (values.edit_percetn_wave === '')          errors.edit_percetn_wave = 'Обязательное поле.';
                    if (values.edit_percent_markup_spread === '') errors.edit_percent_markup_spread = 'Обязательное поле.';
                    if (!values.edit_task_channel_id)             errors.edit_task_channel_id = 'Обязательное поле.';
                    if (!values.edit_task_obj[0] && values.edit_task_type === 'react') errors.edit_task_obj = 'Обязательное поле.';

                    return errors;
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setTimeout(() => {
                        if (values.task_type !=='react') values.task_obj = [];

                        setSubmitting(false);
                        props.editTask({
                            token : props.token,
                            edit_task_id : props.editItem.task_id,
                            edit_task_auto : props.editItem.active ?? true,
                            edit_task_channel_id : values.edit_task_channel_id,
                            edit_task_type : values.edit_task_type,
                            edit_task_count_actions : values.edit_task_count_actions,
                            edit_task_obj : values.edit_task_obj,
                            edit_task_time_out : values.edit_task_time_out,
                            edit_count_action_per_timeout : values.edit_count_action_per_timeout,
                            edit_percetn_wave : values.edit_percetn_wave,
                            edit_percent_markup_spread : values.edit_percent_markup_spread,
                            task_group : values.edit_task_group,
                        });
                        document.querySelector('#editAutoTask button[class="btn-close"]').click();
                    }, 400);
                }}
            >
                {({isSubmitting}) => (
                    <Form>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="editAutoTaskTitle">
                                Changing autotask data
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body" style={{maxHeight: '70vh'}}>
                            <span>
                                Task id: #{props.editItem.task_id} | Channel id: #{props.editItem.task_id_channel}
                            </span>

                            <ErrorMessage name="edit_task_type" component="div"/>
                            <div className="form-floating mt-3">
                                <Field as="select" className="form-control" id="edit_task_type" type="text" name="edit_task_type"
                                   value={taskType}
                                   onChange={(e) => {
                                       setTaskType(e.target.value);

                                       let edit_task_obj_box = document.getElementById('edit_task_obj_box');
                                       if (e.target.value === 'react' ) {
                                           edit_task_obj_box.style.display = 'block';
                                       } else {
                                           edit_task_obj_box.style.display = 'none';
                                       }
                                   }}
                                >
                                    <option value="subs">Подписка</option>
                                    <option value="view">Просмотры</option>
                                    <option value="react">Реакции</option>
                                </Field>
                                <label htmlFor="edit_task_type">edit_task_type</label>
                            </div>

                            <div id="edit_task_channel_id_box" className="mt-3" >
                                <ErrorMessage name="edit_task_channel_id" component="div"/>
                                <div className="form-floating mt-3">
                                    <Field id="edit_task_channel_id" className="form-control" type="text" name="edit_task_channel_id"
                                       value={task_id_channel}
                                       onChange={(e) => {
                                           set_task_id_channel(e.target.value);
                                       }}
                                    />
                                    <label htmlFor="edit_task_channel_id">edit_task_channel_id</label>
                                </div>
                            </div>

                            {/*<ErrorMessage name="edit_task_group" component="div"/>*/}
                            {/*<div className="form-floating mt-3">*/}
                            {/*    <Field className="form-control" id="edit_task_group" type="text" name="edit_task_group"*/}
                            {/*           value={task_group}*/}
                            {/*           onChange={(e) => {*/}
                            {/*               set_task_group(e.target.value);*/}
                            {/*           }}*/}
                            {/*    />*/}
                            {/*    <label htmlFor="edit_task_group">edit_task_group</label>*/}
                            {/*</div>*/}

                            <ErrorMessage name="edit_task_group" component="div"/>
                            <div className="form-floating mt-3">
                                <Field as="select" className="form-control" id="edit_task_group" type="text" name="edit_task_group"
                                       value={task_group}
                                       onChange={(e) => {
                                           set_task_group(e.target.value);
                                       }}
                                >
                                    <option value="all">All</option>
                                    {getGroupList()}
                                </Field>
                                <label htmlFor="edit_task_group">edit_task_group</label>
                            </div>















                            <ErrorMessage name="edit_task_count_actions" component="div"/>
                            <div className="form-floating mt-3">
                                <Field className="form-control" id="edit_task_count_actions" type="text" name="edit_task_count_actions"
                                   value={task_count_actions}
                                   onChange={(e) => {
                                       set_task_count_actions(e.target.value);
                                   }}
                                />
                                <label htmlFor="edit_task_count_actions">edit_task_count_actions</label>
                            </div>

                            <ErrorMessage name="edit_task_obj" component="div"/>
                            <div id="edit_task_obj_box" className="form-floating mt-3" style={ (taskType === 'react') ? {display: 'block'} : {display: 'none'}} >

                                <Select
                                    options={taskReactions}
                                    value={task_obj}
                                    id="edit_task_obj"
                                    name="edit_task_obj"
                                    isMulti={true}
                                    isSearchable={false}
                                    placeholder="Добавьте реакции.."
                                    components={{
                                        IndicatorSeparator: () => null // возле стрелки скрываем разделитель "|"
                                    }}
                                    menuIsOpen={menuIsOpenTask} // состояние открытия select
                                    onChange={(e) => {
                                        // this.setState({ menuIsOpen: true});
                                        set_task_obj(e)
                                    }}
                                    onFocus={ () => {setMenuIsOpenTask(!menuIsOpenTask)}}
                                    onBlur={ () => {setMenuIsOpenTask(!menuIsOpenTask)}}
                                    // menuIsOpen={true} // раскрывает список для тестирования меню.
                                />

                                <label htmlFor="edit_task_obj">edit_task_obj</label>
                                <div id="edit_arrowCustom"
                                     onMouseDown = {(e) => e.preventDefault()}
                                     onMouseUp   = {(e) => {
                                         let taskObjInput = document.querySelector('#edit_task_obj input');
                                         menuIsOpenTask ? taskObjInput.blur() : taskObjInput.focus();
                                     }}
                                >
                                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true"
                                         focusable="false" className="css-tj5bde-Svg">
                                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"/>
                                    </svg>
                                </div>
                            </div>

                            <ErrorMessage name="edit_task_time_out" component="div"/>
                            <div className="form-floating mt-3">
                                <Field className="form-control" id="edit_task_time_out" type="text" name="edit_task_time_out"
                                   value={task_time_out}
                                   onChange={(e) => {
                                       set_task_time_out(e.target.value);
                                   }}
                                />
                                <label htmlFor="edit_task_time_out">edit_task_time_out</label>
                            </div>

                            <ErrorMessage name="edit_count_action_per_timeout" component="div"/>
                            <div className="form-floating mt-3">
                                <Field className="form-control" id="edit_count_action_per_timeout" type="text" name="edit_count_action_per_timeout"
                                   value={edit_count_action_per_timeout}
                                   onChange={(e) => {
                                       set_edit_count_action_per_timeout(e.target.value);
                                   }}
                                />
                                <label htmlFor="edit_count_action_per_timeout">edit_count_action_per_timeout</label>
                            </div>

                            <ErrorMessage name="edit_percetn_wave" component="div"/>
                            <div className="form-floating mt-3">
                                <Field className="form-control" id="edit_percetn_wave" type="text" name="edit_percetn_wave"
                                   value={edit_percetn_wave}
                                   onChange={(e) => {
                                       set_edit_percetn_wave(e.target.value);
                                   }}
                                />
                                <label htmlFor="edit_percetn_wave">edit_percetn_wave</label>
                            </div>

                            <ErrorMessage name="edit_percent_markup_spread" component="div"/>
                            <div className="form-floating mt-3">
                                <Field className="form-control" id="edit_percent_markup_spread" type="text" name="edit_percent_markup_spread"
                                   value={edit_percent_markup_spread}
                                   onChange={(e) => {
                                       set_edit_percent_markup_spread(e.target.value);
                                   }}
                                />
                                <label htmlFor="edit_percent_markup_spread">edit_percent_markup_spread</label>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>

                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                Save changes
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditAutoTaskFormik;