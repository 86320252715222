import React from 'react';
import axios from "axios";
import {cfg} from "../../../cfg.js";

export async function AddAccountApi(formData, i, curProxy, group, category) {
    let data  = '';
    let error = '';

    axios.post(
        cfg.addAccountApiUrl,
        formData,
        {
            method: 'POST',
            headers: {
                "Content-type": "multipart/form-data",
            },
        }
    )
        .then((response) => {
            data = response.data.status + ": " + JSON.stringify(response) + '; ';
        })
        .catch((err) => {
            error = err.data.status + ": " + JSON.stringify(err) + '; ';
        });

    return {
        id: i,
        data: data,
        error: error,
        proxy: curProxy,
        group: group,
        category: category
    };
}

export default AddAccountApi;
