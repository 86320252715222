import React, {useEffect, useState} from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import FetchData, {Fetching} from "../../../api/FetchData";
import {taskReactions} from "../../../staticData/TaskReactions";
import Select from "react-select";
import {cfg} from '../../../../cfg';


const AddTaskFormikModal = (props) => {
    const STEP_LENGHT = 4;
    const [step, setStep] = useState(1);
    const [menuIsOpenTask, setMenuIsOpenTask] = useState(false);
    const [curMode, setMode] = useState('fast');
    const [task_count_actions, set_task_count_actions] = useState(1);
    const task_time_out_data = {
        'fast': (task_count_actions / 10 ) >= 1 ? (task_count_actions / 10 ).toFixed(0) * 1 : 1,
        'slow': (task_count_actions / 100) >= 1 ? (task_count_actions / 100).toFixed(0) * 1 : 1
    };
    const formDataBase = {
        token:           props.token,
        task_auto:       false,
        task_channel_id: '',
        task_target:     '',
        group:           'all',
        task_type:       'subs',
        task_obj:        [],
        task_count_actions: task_count_actions,
        percetn_wave:             {'fast': 15, 'slow': 5  }[curMode],
        percent_markup_spread:    {'fast': 15, 'slow': 15 }[curMode],
        task_time_out:            {'fast': 10, 'slow': 180}[curMode],
        count_action_per_timeout: task_time_out_data[curMode] * 1,
    }
    const [formData, setFormData] = useState(formDataBase);

    /** Валидация 1-3 этапа, при нажатии кнопки "next" **/
    const stepsValidation = {
        step1: function () {
            let isValid = true;
            let task_type_err = document.getElementById('task_type_err');
            if (!formData.task_type) {
                task_type_err.classList.remove("d-none");
                isValid = false;
            }

            return isValid;
        },
        step2: function () {
            let isValid = true;
            let task_channel_id_err = document.getElementById('task_channel_id_err');
            if ((formData.task_auto || formData.task_type === 'subs') && !formData.task_channel_id) {
                task_channel_id_err.classList.remove("d-none");
                isValid = false;
            }

            let task_target_err = document.getElementById('task_target_err');
            if (!formData.task_target) {
                task_target_err.classList.remove("d-none");
                isValid = false;
            }

            return isValid;
        },
        step3: function () {
            let isValid = true;
            let task_group_err = document.getElementById('task_group_err');
            if (!formData.task_type) {
                task_group_err.classList.remove("d-none");
                isValid = false;
            }

            let task_count_actions_err = document.getElementById('task_count_actions_err');
            if (!formData.task_count_actions || formData.task_count_actions < 1 || formData.task_count_actions > props.taskCountActionsLimit) {
                task_count_actions_err.classList.remove("d-none");
                isValid = false;
            }

            let add_task_obj_err = document.getElementById('add_task_obj_err');
            if (formData.task_type === 'react' && !formData.task_obj[0]) {
                add_task_obj_err.classList.remove("d-none");
                isValid = false;
            }

            return isValid;
        },
    }

    /** стили для аниации прогрессбара **/
    const css = `.AddTaskModalProgressBar > div { transition: .2s; width: ${(step / STEP_LENGHT) * 100}%; }`;

    useEffect(() => {
        setFormData({ ...formData, task_count_actions: task_count_actions });
        setFormData({ ...formData, count_action_per_timeout: (task_time_out_data[curMode] * 1).toFixed(0) * 1 });
    }, [curMode, task_count_actions])

    const handleNext = () => {
        /** проверка на валидность до перехода **/
        if (stepsValidation[`step${step}`]()) setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    async function handleSubmit (event) {
        event.preventDefault(); // reset default form event
        if (formData.task_type !=='subs' && !formData.task_auto) formData.task_channel_id = '';
        if (formData.task_type ==='react') {
            let curReactions = [];
            formData.task_obj.forEach((e) => curReactions.push(e.value) );
            formData.task_obj = curReactions;
        } else {
            formData.task_obj = [];
        }

        Fetching({
            debug: false,
            method: 'POST',
            values: formData,
            request: cfg.addTaskApiUrl,
        })
            .then(r => {
                setStep(1);
                set_task_count_actions(1);
                setFormData(formDataBase);
                props.setMsg(`Статус добавления задачи: ${r.message}` ?? `Ошибка доавбления задачи: ${JSON.stringify(r)}`);
                document.querySelector(`#addTaskModal .btn-close`).click();
            });
    }

    /** список уникальных групп, для селекта group **/
    function getGroupList() {
        let options = []
        props.groupList.map(e => options.push(<option key={e} value={e}>{e}</option>));
        return options;
    }

    /** Custom array for edit task (smiles) **/
    useEffect(() => {
        let arrow = document.querySelector('#AddTaskModalForm div[class$="-indicatorContainer"]:last-child');
        let arrowContainer = document.querySelector('#add_task_obj > div > div:nth-child(2)');
        if (arrow && arrowContainer && !arrow.style.display) {
            arrow.style.display = 'none';
            arrowContainer.appendChild( document.getElementById('addTask_arrowCustom') );
        }
    });

    return (
        <div className="modal fade" id="addTaskModal" tabIndex="-1"
             aria-labelledby="addTaskModalTitle"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5"
                            id="addTaskModalTitle">Add new task</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>

                    <Form id="AddTaskModalForm" onSubmit={handleSubmit} >
                        <div className="modal-body" style={{minHeight: '250px'}}>
                            <style>{css}</style>
                            <ProgressBar
                                className="AddTaskModalProgressBar"
                                now={(step / STEP_LENGHT) * 100}
                                label={((step / STEP_LENGHT) * 100).toFixed(0) + '%'}
                            />

                            {step === 1 && (
                                <Form.Group controlId="formStep1">
                                    <h6 className="mt-2">Step 1: Task type</h6>

                                    <div id="task_type_err" className="d-none">Обязательное поле</div>
                                    <div className="form-floating mt-3">
                                        <Form.Select
                                            className="form-control mb-2"
                                            type="text"
                                            name="task_type"
                                            value={formData.task_type}
                                            onChange={handleInputChange}
                                        >
                                            <option value="subs">Подписка</option>
                                            <option value="view">Просмотры</option>
                                            <option value="react">Реакции</option>
                                        </Form.Select>
                                        <Form.Label>Type</Form.Label>
                                    </div>
                                    {formData.task_type !== 'subs' &&
                                        <div className="d-flex mb-2">
                                            <Form.Check
                                                type="switch"
                                                id="task-auto"
                                                checked={formData.task_auto}
                                                onChange={(e) => {
                                                    setFormData({ ...formData, task_auto: e.target.checked });
                                                }}
                                            />
                                            <label htmlFor="task-auto" className="cursor-pointer">Auto</label>
                                        </div>
                                    }
                                </Form.Group>
                            )}

                            {step === 2 && (
                                <Form.Group controlId="formStep2">
                                    <h6 className="mt-2">Step 2: Add target</h6>

                                    <div id="task_channel_id_err" className="d-none">Обязательное поле</div>
                                    {(formData.task_auto || formData.task_type === 'subs') &&
                                        <div className="form-floating mt-3">
                                            <Form.Control
                                                className="form-control"
                                                type="text"
                                                name="task_channel_id"
                                                value={formData.task_channel_id}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Label>Channel id</Form.Label>
                                        </div>
                                    }

                                    <div id="task_target_err" className="d-none">Обязательное поле</div>
                                    <div className="form-floating mt-3">
                                        <Form.Control
                                            className="form-control"
                                            type="text"
                                            name="task_target"
                                            value={formData.task_target}
                                            onChange={handleInputChange}
                                        />
                                        <Form.Label>Target (URL)</Form.Label>
                                    </div>
                                </Form.Group>

                            )}

                            {step === 3 && (
                                <Form.Group controlId="formStep3">
                                    <h6 className="mt-2">Step 3: Add group</h6>

                                    <div id="task_group_err" className="d-none">Обязательное поле</div>
                                    <div className="form-floating mt-3">
                                        <Form.Select
                                            className="form-control mb-2"
                                            type="text"
                                            name="group"
                                            value={formData.group}
                                            onChange={handleInputChange}
                                        >
                                            <option value="all">All</option>
                                            {getGroupList()}
                                        </Form.Select>
                                        <Form.Label>Group</Form.Label>
                                    </div>



                                    <div id="task_count_actions_err" className="d-none">Обязательное поле</div>
                                    <div className="form-floating mt-3">
                                        <Form.Control
                                            min="1"
                                            max={props.taskCountActionsLimit}
                                            className="form-control"
                                            type="number"
                                            name="task_count_actions"
                                            value={task_count_actions}
                                            onChange={ e => {
                                                set_task_count_actions(e.target.value);
                                                setFormData({ ...formData, task_count_actions: e.target.value });
                                            }}
                                        />
                                        <Form.Label>Actions count ({`from 1 to ${props.taskCountActionsLimit}`})</Form.Label>
                                    </div>

                                    <div id="add_task_obj_err" className="d-none">Обязательное поле</div>
                                    {formData.task_type === 'react' &&
                                        <div className="form-floating mt-3">
                                            <Select
                                                className="task_obj"
                                                options={taskReactions}
                                                value={formData.task_obj}
                                                id="add_task_obj"
                                                name="add_task_obj"
                                                isMulti={true}
                                                isSearchable={false}
                                                placeholder="Добавьте реакции.."
                                                components={{
                                                    IndicatorSeparator: () => null // возле стрелки скрываем разделитель "|"
                                                }}
                                                menuIsOpen={menuIsOpenTask} // состояние открытия select
                                                onChange={(task_obj) => {
                                                    setFormData({ ...formData, task_obj: task_obj });
                                                }}
                                                onFocus={ () => {setMenuIsOpenTask(!menuIsOpenTask)}}
                                                onBlur={ () => {setMenuIsOpenTask(!menuIsOpenTask)}}
                                                // menuIsOpen={true} // раскрывает список для тестирования меню.
                                            />
                                            <label style={{color: '#95999d'}} className="form-label" htmlFor="add_task_obj">Reactions</label>
                                            <div id="addTask_arrowCustom"
                                                 onMouseDown = {(e) => e.preventDefault()}
                                                 onMouseUp   = {(e) => {
                                                     let taskObjInput = document.querySelector('#add_task_obj input');
                                                     menuIsOpenTask ? taskObjInput.blur() : taskObjInput.focus();
                                                 }}
                                            >
                                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true"
                                                     focusable="false" className="css-tj5bde-Svg">
                                                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"/>
                                                </svg>
                                            </div>
                                        </div>
                                    }

                                </Form.Group>
                            )}

                            {step === 4 && (
                                <Form.Group controlId="formStep4">
                                    <h6 className="mt-2">Step 4: Add preset</h6>

                                    <Form.Check
                                        inline
                                        name="task_radio"
                                        type="radio"
                                        value="fast"
                                        id="fast"
                                        defaultChecked={true}
                                        onChange={e => setMode(e.target.value)}
                                    />
                                    <label className="me-3 cursor-pointer" htmlFor="fast">
                                        fast
                                    </label>

                                    <Form.Check
                                        inline
                                        name="task_radio"
                                        type="radio"
                                        value="slow"
                                        id="slow"
                                        onChange={e => setMode(e.target.value)}
                                    />
                                    <label className="me-3 cursor-pointer" htmlFor="slow">
                                        slow
                                    </label>

                                </Form.Group>
                            )}
                        </div>

                        <div className="modal-footer px-0 d-flex justify-content-between">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>

                            <div className="d-flex justify-content-between">
                                {step > 1 && (
                                    <Button className="me-2" variant="light" onClick={handlePrevious}>
                                        Previous
                                    </Button>
                                )}

                                {step < STEP_LENGHT ? (
                                    <Button variant="primary" onClick={handleNext}>
                                        Next
                                    </Button>
                                ) : (
                                    <button type="submit" className="btn btn-success">
                                        Add task
                                    </button>
                                )}
                            </div>

                        </div>
                    </Form>

                </div>
            </div>
        </div>
    );
};

export default AddTaskFormikModal;