import React from 'react';

const Footer = () => {
    return (
        <footer className="py-3 mt-4 text-body-secondary border-top">
            <div className="container">
                © 2024
            </div>
        </footer>
    );
};

export default Footer;