import React, {useEffect, useState} from 'react';
import {cfg} from "../../../cfg.js";
import GetTasksAutoApi from "./GetTasksAutoApi";

const EditAutoTaskApi = (props) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({data: []});

    useEffect(() => {
        fetchEditAutoTask().then();
    }, [props.run]);

    async function fetchEditAutoTask() {
        fetch(cfg.editAutoTaskUrl,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: props.token,
                    task_id: props.task.edit_task_id,
                    task_type: props.task.edit_task_type,
                    task_count_actions: props.task.edit_task_count_actions,
                    task_obj: props.task.edit_task_obj,
                    task_time_out: props.task.edit_task_time_out,
                    active: props.task.edit_task_auto,
                    task_channel_id: props.task.edit_task_channel_id.toString(),
                    count_action_per_timeout: props.task.edit_count_action_per_timeout,
                    percetn_wave: props.task.edit_percetn_wave,
                    percent_markup_spread: props.task.edit_percent_markup_spread,
                    task_group: props.task.task_group,
                })
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setData(result);
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }

    if (error) {
        return <div>Ошибка: {JSON.stringify(error)}</div>;
    } else if (!isLoaded) {
        return <h6 className="text-center">Загрузка...</h6>;
    } else {
        if (!['ok', 'succsess'].includes(data.status)) {
            props.logs.push(JSON.stringify(data));
            return <div>Упс, что-то не так. Смотрите логи.</div>;
        }

        return <div>Таск успешно изменен: {JSON.stringify(data)}</div>;
    }
};

export default EditAutoTaskApi;