import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {cfg} from './cfg';
/** Api **/
import AuthByTokenApi    from "./components/api/AuthByTokenApi";
import GetTasksApi       from "./components/api/Tasks/GetTasksApi";
import GetTasksAutoApi   from "./components/api/Tasks/GetTasksAutoApi";
import GetSessionsApi    from "./components/api/Sessions/GetSessionsApi";
import GetAllSessionsApi from "./components/api/Sessions/GetAllSessionsApi";
/** Components **/
import Header from "./components/Header";
import Footer from "./components/Footer";
/** pages **/
import Home from "./pages/Home";
import Faq from "./pages/Faq";
import About from "./pages/About";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NoPage from "./pages/NoPage";
import Dashboard from "./pages/Dashboard";

export default function App() {
    /** global **/
    const [logs, setLogs] = useState([]);
    const [msg, setMsg] = useState('Здесь отобразится статус апи ответа - отправьте любой апи запрос.');
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    /** лимит задач по кол-ву добавленных, рабочих сессий **/
    const [taskCountActionsLimit, setTaskCountActionsLimit] = useState(0);
    /** User data **/
    const [isLogged, setIsLogged]           = useState(false);
    const [userRole, setUserRole]           = useState('');
    /** Accaunts & Sessions **/
    const [curUnicGroups, setCurUnicGroups] = useState([]); // скисок групп (без дубликатов) - для создания задач
    const [sessionsGroup, setSessionsGroup] = useState([]); // группы из списка сессий юзера
    const [sessionsGroupAdmin, setSessionsGroupAdmin] = useState([]); // группы из списка всех сессий (админка)
    const [list, updateList]                = useState(<GetSessionsApi    token={cookies.token} userRole={userRole} setTaskCountActionsLimit={setTaskCountActionsLimit} setMsg={setMsg} setLogs={setLogs} logs={logs} setSessionsGroup={setSessionsGroup} />);
    const [listAll, updateListAll]          = useState(<GetAllSessionsApi token={cookies.token} userRole={userRole} setTaskCountActionsLimit={setTaskCountActionsLimit} setMsg={setMsg} setLogs={setLogs} logs={logs} setSessionsGroupAdmin={setSessionsGroupAdmin} />);
    /** Tasks **/
    const [tasks, updateTasks]              = useState(<GetTasksApi       token={cookies.token} setMsg={setMsg} logs={logs} />);
    const [tasksAuto, updateTasksAuto]      = useState(<GetTasksAutoApi   token={cookies.token} setMsg={setMsg} logs={logs} curUnicGroups={curUnicGroups} />);

    const SESSION_LIMIT = 3600000; // one hour
    let   userTimeOut = SESSION_LIMIT;

    // лимит сессий (отключает автообновление списков) + базовый статус
    useEffect(() => {
        setMsg('Здесь отобразится статус апи ответа - отправьте любой апи запрос.');

        // лимит сессий
        setInterval(() => {
            if (userTimeOut < 0 && (cookies.token !== null || typeof cookies.token === 'string')) {
                cfg.IS_GET_LISTS_BY_INTERVAL = false
                // setMsg('Истекло время сессии.'); // сбрасывается при обновлении стр, возможно стоит писать в куки нотис, который отображать
                // handleLogin({ token: null, isLogged: false });
                // window.location.replace(window.location.origin + '/login');
            } else {
                cfg.IS_GET_LISTS_BY_INTERVAL = true;
            }

            userTimeOut -= 60000;
        }, 60000); // every minutes

        document.body.onclick = () => {
            userTimeOut = SESSION_LIMIT;
        }
    }, []);

    // группы доступные для добавления/изменения задач
    useEffect(() => {
        if (userRole === 'admin') {
            setCurUnicGroups(sessionsGroupAdmin)
        } else {
            setCurUnicGroups(sessionsGroup);
        }

        updateTasksAuto(<GetTasksAutoApi token={cookies.token} setMsg={setMsg} logs={logs} curUnicGroups={curUnicGroups} />)
    }, [sessionsGroupAdmin, sessionsGroup]);

    useEffect(() => {
        updateListAll(<GetAllSessionsApi token={cookies.token} userRole={userRole} setTaskCountActionsLimit={setTaskCountActionsLimit} setMsg={setMsg} logs={logs} setSessionsGroupAdmin={setSessionsGroupAdmin} />)
    }, [userRole]);

    // прямое получение кук
    // const handleToken = () => {
    //     const thisToken = document.cookie.split('; ').filter(row => row.startsWith('token=')).map(c=>c.split('=')[1])[0];
    //     setCookie("token", thisToken, { path: "/" });
    // }

    const handleLogin = (user) => {
        setUserRole(user.role);
        setIsLogged(user.isLogged);
        setCookie("token", user.token, { path: "/" });
        cookies.token = user.token;
        if (user.isLogged) {
            updateTasks     (<GetTasksApi       token={user.token} setMsg={setMsg} logs={logs} />);
            updateTasksAuto (<GetTasksAutoApi   token={user.token} setMsg={setMsg} logs={logs} curUnicGroups={curUnicGroups} />);
            updateList      (<GetSessionsApi    token={user.token} userRole={user.role} setTaskCountActionsLimit={setTaskCountActionsLimit} setMsg={setMsg} setLogs={setLogs} logs={logs} setSessionsGroup={setSessionsGroup} />);
            updateListAll   (<GetAllSessionsApi token={user.token} userRole={user.role} setTaskCountActionsLimit={setTaskCountActionsLimit} setMsg={setMsg} setLogs={setLogs} logs={logs} setSessionsGroupAdmin={setSessionsGroupAdmin} />);
        }
    }

    return (
    <BrowserRouter>
        <AuthByTokenApi token={cookies.token} setIsLogged={setIsLogged} setUserRole={setUserRole}/>
        <Routes>
            <Route path="/" element={<Header onLogOut={handleLogin} role={userRole} isLogged={isLogged} msg={msg}/>}>
                <Route index element={<Home />} />
                <Route path="dashboard" element={<Dashboard
                    token={cookies.token}                               setMsg={setMsg}
                    curUnicGroups={curUnicGroups}                       userRole={userRole}
                    setLogs={setLogs}                                   logs={logs}
                    setIsLogged={setIsLogged}                           isLogged={isLogged}
                    sessionsGroupAdmin={sessionsGroupAdmin}             sessionsGroup={sessionsGroup}
                    updateList={updateList}                             list={list}
                    updateListAll={updateListAll}                       listAll={listAll}
                    updateTasks={updateTasks}                           tasks={tasks}
                    updateTasksAuto={updateTasksAuto}                   tasksAuto={tasksAuto}
                    setTaskCountActionsLimit={setTaskCountActionsLimit} taskCountActionsLimit={taskCountActionsLimit}
                />}/>
                <Route path="faq"       element={<Faq/>} />
                <Route path="about"     element={<About/>} />
                <Route path="login"     element={<Login  isLogged={isLogged} onLogin={handleLogin}  setMsg={setMsg} />} />
                <Route path="sign-up"   element={<SignUp isLogged={isLogged} onSignUp={handleLogin} setMsg={setMsg} />} />
                <Route path="*"         element={<NoPage/>} />
            </Route>
        </Routes>
        <Footer />
    </BrowserRouter>
    );
}
